import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { faqData } from './AccordionData';
import 'react-light-accordion/demo/css/index.css';
import "../../styles/HomeFaq.css";
import { useState } from 'react';
const App = () => {
  const [selected, setSelected] = useState(null)
  const arraySlice = faqData.slice(0, 4);
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null)
    }
    setSelected(i)
  }
  return (
    <div className='container text-white ' data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="1000">
      <div className=' faq_section v'>
        <h1 className='accordion_heading_home heading_bg_faq text-dark'><span>FAQ</span></h1>
        <div className='accordion_outer_div'>
          {
            arraySlice.map((item, i) => {
              return (
                <div key={i} className="item">
                  <div className="title" onClick={() => toggle(i)}>
                    <h5 className="faq_question">{item.question}</h5>
                    <span className='plus_button'>{selected === i ? '-' : '+'}</span>
                  </div>
                  <div className={selected === i ? 'content show' : 'content'}>
                    <h5 className="ms-4 me-4 faq_answer">{item.answer}</h5>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
      <div align="center" className='mt-4' ><a href="/faq"><button className='btn view_more_btn text-white '>VIEW MORE</button></a></div>
    </div>
  )
}

export default App;
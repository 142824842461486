import React from "react";
import "../../../styles/NctFooter.css";
import twitter_icon from "../../../images/twitter_logo.png";
import facebook_icon from "../../../images/facebook_logo.png";
import instgram_icon from "../../../images/instagram_logo.png";
import youtube_icon from "../../../images/youtube_logo.png";
import linkedin from '../../../images/linkedin_logo.png'

function NctFooter() {
    return (
        <>
            <footer className=" " style={{ backgroundColor: "#240046" }}>
                <div className="container-fluid footer_text" style={{fontFamily:"Montserrat"}}>
                    <div className="container text-white ">
                        <div className="row">
                            <div className="col-sm-12 col-md-4 mt-3">

                                <ul className="list-unstyled contact_links">
                                    <li className=""><i className=" fa-solid fa-envelope " aria-hidden="true"></i>
                                        <a  href="mailto:+info@wtcf.cricket" className="ms-2 nct_contact text-decoration-none text-white">info@wtcf.cricket</a>
                                    </li>
                                    <li className="mt-2"><i className="fa fa-phone"></i>
                                        <a href="tel:+7305691114" className="ms-2 nct_contact text-decoration-none text-white">7305691114</a>
                                    </li>
                                    <li className="mt-2"><i className=" fa fa-phone"></i>
                                        <a href="tel:+7305691115" className="ms-2 nct_contact text-decoration-none text-white">7305691115</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-sm-12 col-md-4 mt-3">
                                <ul className="list-unstyled">
                                    <li className="privacy_policy_text"><a className="text-decoration-none text-white" href="/privacy-policy">Privacy Policy</a> | <a className="text-decoration-none text-white" href="/terms-and-conditions">Terms of use</a></li>
                                    <li className="mt-2 nct_contact">Copyrights WTCF LLC, All Rights Reserved</li>
                                </ul>
                            </div>
                            <div className="row col-sm-12 col-md-4  mt-3 social_media_links">
                                <div className="">
                                    <h6
                                        className="text-white follow_us_text"
                                        style={{ fontFamily: "montserrat" }}
                                    >
                                        Follow Us
                                    </h6>
                                    <ul className="list-unstyled d-flex justify-content-start">
                                        <li>
                                            <a
                                                className="text-white social_links_bottom"
                                                href="https://www.facebook.com/World-Tennis-Cricket-Federation-109797411735646"
                                                target="_blank"
                                            >
                                                <img className="social_icon" src={facebook_icon} />
                                            </a>
                                        </li>
                                        <li className="ms-4">
                                            <a
                                                className="text-white social_links_bottom"
                                                href="https://twitter.com/WTCFofficial"
                                                target="_blank"
                                            >
                                                <img className="social_icon" src={twitter_icon} />
                                            </a>
                                        </li>
                                        <li className="ms-4">
                                            <a
                                                className="text-white social_links_bottom"
                                                href="https://www.youtube.com/channel/UC1LG-nsVj4qB3DRzMBeOcFA"
                                                target="_blank"
                                            >
                                                <img className="social_icon" src={youtube_icon} />
                                            </a>
                                        </li>
                                        <li className="ms-4">
                                            <a
                                                className="text-white social_links_bottom"
                                                href="https://www.instagram.com/wtcf.official"
                                                target="_blank"
                                            >
                                                <img className="social_icon" src={instgram_icon} />
                                            </a>
                                        </li>
                                        <li className="ms-4">
                                            <a
                                                className="text-white social_links_bottom"
                                                href="https://www.linkedin.com/company/wtcf-cricket/"
                                                target="_blank"
                                            >
                                                <img className="social_icon" src={linkedin} />
                                            </a>
                                        </li>
                                        {/* <li className="ms-4">
                                            <a
                                                className="text-white social_links_bottom"
                                                href="https://www.linkedin.com/company/wtcf-cricket/"
                                                target="_blank"
                                            >
                                               <i className="fab fa-2x fa-facebook-f fb_icon social_icon1"></i>
                                            </a>
                                        </li> */}
                                        </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )

}
export default NctFooter;
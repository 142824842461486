import "../../styles/mobile_event.css";
import world_cup from "../../images/events_2.png";
import super_league from "../../images/event_super_league.png";
import Carousel from "react-bootstrap/Carousel";

function Events_phone() {
  return (
    <div className="mob_event_sign"  data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="1000">
      <div className="events_sig_border">
        <h1 className="heading_bg_me ">WTCF SIGNATURE EVENTS</h1>
      </div>
      <div className="container-fluid event_phone_clr">
        <Carousel slide={false} >
          <Carousel.Item >
            <div>
              <div className="row py-4">
                <div className="col-2"></div>
                <div className="col">
                  <img className="carousel_img_phone" src={world_cup} alt="First slide" />
                </div>
                <div className="col-2"></div>
              </div>

              <div className="row text-align-justify">
                <div className="col-2"></div>
                <div className="col">
                  <h1 className=" text-white">WTCF WORLD CUP</h1>
                  <p className="text-white">
                    WTCF World Cup, the marquee event on the playing calendar of
                    the WTCF, will be a match that draws the top tennis ball cricket
                    players in the world. The competition will assist in building
                    the ideal framework for other nations to embrace this variant of
                    cricket.
                  </p>
                  {/* <p className="text-warning">Learn More</p> */}
                </div>
                <div className="col-2"></div>
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item >
            <div>
              <div className="row py-4">
                <div className="col-2"></div>
                <div className="col">
                  <img className="carousel_img_phone" src={super_league} alt="First slide" />
                </div>
                <div className="col-2"></div>
              </div>
              <div className="row text-align-justify">
                <div className="col-2"></div>
                <div className="col">
                  <h1 className=" text-white">WTCF SUPER LEAGUE</h1>
                  <p className="text-white">
                  The perfect combination between strategy and strength will be discovered in the WTCF TENNIS BALL SUPER LEAGUE (TBSL). Teams will be formed after a competitive bidding process where the talent with highest rating receives the highest value.
</p><p className="text-white">This will significantly motivate the players to keep raising their level of play. There will be excellent bowling and hard-hitting batting throughout the competition. This powerhouse tournament will be a treat to watch for all ages.

                  </p>
                  {/* <p className="text-warning">Learn More</p> */}
                </div>
                <div className="col-2"></div>
              </div>
            </div>
          </Carousel.Item>

        </Carousel>
      </div>
    </div>
  );
}

export default Events_phone;

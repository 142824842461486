import React from "react";
import ReactDOM from "react-dom/client";
// import {B}
import "./index.css";
import App from "./pages/App";
// import { NewsT } from "./components/NewsTesting/NewsT";
// import { SectionTitle } from "./components/elements/SectionTitle";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
    
    {/* <NewsT/> */}
    {/* <Home /> */}
    {/* <Accordion/> */}
  </React.StrictMode>
);

import "../../styles/Contact.css";
import contact_img from "../../images/contact-us.png";
import Statue_of_liberty from "../../images/state-of-liberty.png";
import Form from "react-bootstrap/Form";
import React, { useState } from "react";
import axios from "axios";
import qs from "qs";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Contact() {
  const inputHandler = (e) => {
    const { value, maxLength } = e.target;
    if (String(value).length >= maxLength) {
      e.preventDefault();
      return;
    }
  };
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [category, setCategory] = useState("");
  const [message, setMessage] = useState("");

  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handlePhone = (e) => {
    setPhone(e.target.value);
  };
  const handleCategory = (e) => {
    setCategory(e.target.value);
  };
  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // notify = () => toast("Submitted successfully");
    setName("");
    setEmail("");
    setPhone("");
    setCategory("");
    setMessage("");

    const reqData = {
      mod: "WTCF",
      actionType: "add-wtcf-query",
      subAction: JSON.stringify({
        NAME: name,
        EMAIL: email,
        PHONE_NUMBER: phone,
        CATEGORY: category,
        MESSAGE: message,
      }),
    };

    axios({
      method: "post",
      url: "https://api.cricdost.com",
      data: qs.stringify(reqData),
      header: { "content-type": "application/x-www-form-urlencoded" },
    })
      .then((response) => {

        if (response.data.XSCStatus == -1) {
          toast.warning("Invalid details", {
            autoClose: 3000,
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          toast.success("Submitted Successfully", {
            autoClose: 1000,
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })

      .catch((error) => {
        // console.log(error.data);
      });
  };

  return (
    <div className="top_image_view">
      <div>
        <img className="contact_image" src={contact_img} alt="" />
      </div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6 col-lg-6 ">
            <p className="text-white map_text">
              Let's start a <span className="text-warning">conversation</span>
            </p>
            <div className="mapouter ">
              <div className="gmap_canvas">
                <iframe
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?q=99,wall%20street,STE%204011,new%20york%20city,NY%2010005,USA&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                ></iframe>
                <a href="https://fmovies-online.net"></a>
                <a href="https://www.embedgooglemap.net"></a>
              </div>
            </div>

            <div className="">
              <div className="row mt-md-3 address_container">
                <div className="col-1">
                  <i
                    className="ms-2 fa fa-address-book address_icon "
                    aria-hidden="true"
                  ></i>
                </div>
                <span className=" col-11 address_field">
                  <h5 className=" ms-3 address_email_head text-warning">
                    Address
                  </h5>
                  <p className="ms-3 address_email_text text-white">
                    99, Wall Street STE 4011 New York City, NY 10005(USA)
                  </p>
                </span>
              </div>
              <div className="row mt-md-4 email_container">
                <div className="col-1">
                  <i
                    className="ms-2 fa fa-envelope email_icon"
                    aria-hidden="true"
                  ></i>
                </div>
                <span className="col-11 email_field">
                  <h5 className="ms-3 address_email_head text-warning">
                    Email Us
                  </h5>
                  <p className="text-decoration-none ms-3 address_email_text text-white">
                    <a href="mailto:+info@wtcf.cricket" className="text-decoration-none text-white">info@wtcf.cricket</a>
                  </p>
                </span>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4  d-flex justify-content-center ">
            <form onSubmit={handleSubmit} className="contact_form bg-dark">
              <div className="form_header mt-5">
                <p className="form_content text-white">
                  We will get back to you soon!
                </p>
              </div>

              <div className="form-group">
                <label></label>
                <input
                  type="text"
                  className="form_input form-control"
                  align="center"
                  placeholder="Name"
                  name="name"
                  value={name}
                  onChange={handleName}
                  required
                />
              </div>

              <div className="form-group">
                <label></label>
                <input
                  type="email"
                  className="form_input form-control"
                  placeholder="Email"
                  name="email"
                  value={email}
                  onChange={handleEmail}
                  required
                />
              </div>

              <div className="form-group">
                <label></label>
                <input
                  type="number"
                  className="form_input form-control"
                  placeholder="Phone Number"
                  name="phone"
                  value={phone}
                  onChange={handlePhone}
                  maxlength="16"
                  onKeyPress={inputHandler}
                  required
                />
              </div>

              <div className="form-group">
                <label></label>
                <input
                  type="text"
                  className="form_input form-control"
                  placeholder="Subject"
                  name="category"
                  value={category}
                  onChange={handleCategory}
                  required
                />
              </div>

              <div className="form-group">
                <label className="message-box"></label>
                <input
                  type="text"
                  className="form_input form-control"
                  id="message"
                  placeholder="Message"
                  name="message"
                  value={message}
                  onChange={handleMessage}
                  required
                />
              </div>

              <div className="form-group">
                <label></label>
                <input
                  type="submit"
                  className="form_input form-control border-0 text-white "
                  id="submit"
                  value="SUBMIT"
                />
              </div>

              <ToastContainer />
            </form>
          </div>
          <div className="col-lg-2 d-none d-lg-block mt-5">
            <img
              className="statue_of_liberty_image rouded mx-auto d-block"
              src={Statue_of_liberty}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Contact;

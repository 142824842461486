import "../../styles/Events.css";
import sig_event_bac from "../../images/events_cover.png";
import world_cup from "../../images/events_2.png";
import super_league from "../../images/event_super_league.png";
import event_world_cup from "../../images/events_2.png";

import Carousel from "react-bootstrap/Carousel";

// import { EventItem } from "../elements/EventItem";
function Events() {
  return (
    <div
      data-aos="fade-up"
      data-aos-anchor-placement="top-bottom"
      data-aos-duration="1000"
      className="others_events_sign"
    >
      <Carousel slide={false} className="wtcf_event_topics ">
        <Carousel.Item>
          <div className="row">
            <div className="col-2"></div>
            <div className="col-3 text-align-justify event_text_content_">
              <h1 className="text-white wtcf_sign">WTCF WORLD CUP</h1>
              <p className="text-white  event_para">
                The WTCF World Cup, the marquee event on the playing calendar of
                the WTCF, will be a match that draws the top tennis ball cricket
                players in the world. The competition will assist in building
                the ideal framework for other nations to embrace this variant of
                cricket.
              </p>
              {/* <p className="text-warning">Learn More</p> */}
            </div>
            <div className="col-4 ps-5">
              <img
                className="carousel_img1 "
                src={world_cup}
                alt="First slide"
              />
            </div>
            <div className="col-2"></div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="row">
            <div className="col-2"></div>
            <div className="col-3 event_text_content_ text-align-justify">
              <h1 className="text-white wtcf_sign">WTCF SUPER LEAGUE</h1>
              <p className="text-white  event_para">
                The perfect combination between strategy and strength will be
                discovered in the WTCF TENNIS BALL SUPER LEAGUE (TBSL). Teams
                will be formed after a competitive bidding process where the
                talent with highest rating receives the highest value. This will
                significantly motivate the players to keep raising their level
                of play. There will be excellent bowling and hard-hitting
                batting throughout the competition. This powerhouse tournament
                will be a treat to watch for all ages.
              </p>
              {/* <p className="text-warning">Learn More</p> */}
            </div>
            <div className="col-4 ps-5">
              <img
                className=" carousel_img2"
                src={super_league}
                alt="First slide"
              />
            </div>
            <div className="col-2"></div>
          </div>
        </Carousel.Item>
      </Carousel>

      <div>
        <h1 className="events_heading">WTCF SIGNATURE EVENTS</h1>
        <img src={sig_event_bac} className="wtcf_event_bac_cover" />
      </div>
    </div>
  );
}

export default Events;

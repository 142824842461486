import React from "react";
import "../../styles/news-details.css";
import { useLocation } from "react-router-dom";
import SiteData from "../../json/news-details.json";
import { useNavigate } from "react-router-dom";

export const NewsDetails = () => {

  const JsonData = SiteData.NEWS;
  
  const navigate = useNavigate();

  function transfer(index) {
    navigate(`/news-details/${index}`);
    window.location.reload();
  }

  const location = useLocation();
  const lastSegment = location.pathname.split("/").pop();
  // console.log(lastSegment);

  return (
    <div className="news_one top_image_view">
      <section>
        <div>
          <img
            className="img-fluid row"
            src={JsonData[lastSegment].COVER_URL}
            width="100%"
          />
          <div className="container  highlights_content">
            <h2 className="news_heading text-white ">{JsonData[lastSegment].TITLE}</h2>
            <div className="text-white my-1">
              <p>
                <i className="fa fa-calendar" style={{ color: "#E10000" }}></i>{" "}
                23-Aug-2022{" "}
                <span className="sponsor_name text-warning">By WTCF</span>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-7">
              <div>
                <div className="text-white">
                  <p className="news_content mb-4"></p>
                </div>
                <div>
                  <img
                    className="img-fluid"
                    src={JsonData[lastSegment].IMG_URL}
                    width={800}
                    height={150}
                  />
                </div>
                <div className="text-white">
                  <h1 className="news_content mt-5">{JsonData[lastSegment].TITLE}</h1>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: JsonData[lastSegment].CONTENT_HTML,
                    }}
                  ></div>
                  {/* <p>{location.state.CONTENT}</p> */}
                </div>
              </div>
            </div>
            <div className=" text-white col-lg-5">
              <h1>RECENT NEWS</h1>
              {JsonData.map((news,index) => (
                <div key={index} className="row py-2">
                  <div className="col-lg-5" onClick={() => transfer(index)}>
                    {/* <a href={news.URL}> */}
                    <img src={news.IMG_URL} width={160} height={100} />
                    {/* </a> */}
                  </div>
                  <div className="col-lg-7">
                    <div className="text-white">
                      <span>
                        <i
                          className="fa fa-calendar"
                          style={{ color: "#E10000" }}
                        ></i>{" "}
                        {news.DATE}
                      </span>
                      <h6 className="content_heading">{news.TITLE}</h6>
                      <h6 className="news_contents">{news.CONTENT}</h6>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="text-white row">
              <div className="col-md-7"></div>
              <div className="text-white col-md-7">
                <p className="news_content mt-4"></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

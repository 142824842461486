import React from "react";
import wtcf_logo from "../../../images/wtcf_logo.png";
import wtcf_tour_banner from "../../../images/wtcf_tour_banner.png";
import NctDownlaod from "../../../images/NctDownlaod.png";
import "../../../styles/Tournament-info.css";
import { useState, useEffect } from "react";
import list_icon from "../../../images/list_icon.svg";
import { useLocation } from "react-router-dom";
// import { FaBars } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";

const Data = [
  {
    title: "TOURNAMENT INFO",
    path: "/Tournament",
    className: "nav_text",
  },
  {
    title: "REWARDS & PRIZES",
    path: "/Rewards",
    className: "nav_text",
  },
  {
    title: "RULES & REGULATIONS",
    path: "/Rules",
    className: "nav_text",
  },
];
function TournamentHeader() {
  const location = useLocation();

  const pathname = location.pathname;

  // console.log(pathname);

  const [header, setHeader] = useState("header1");
  const [wtcfHeader, setWtcfHeader] = useState("wtcf_header");

  const listenScrollEvent = (event) => {
    if (window.scrollY < 290) {
      setHeader("header1");
    } else if (window.scrollY > 280) {
      setHeader("header2");
    }

    if (window.scrollY < 250) {
      return setWtcfHeader("wtcf_header");
    } else {
      return setWtcfHeader("wtcf_header2");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  const [menu, setMenu] = useState(false);
  const showMenu = () => {
    setMenu(!menu);
  };
  return (
    <>
      <div className="container-fluid header">
        <div className={wtcfHeader}>
          <div className="container">
            <a href="/">
              <img src={wtcf_logo} className="nct_wtcf_logo" alt="" />
            </a>
            <span className="Nct_heading_text text-white">
              National Champions Trophy
            </span>
          </div>
        </div>

        <div className="container">
          <div className="row mt-0">
            <div className="col-md">
              <img src={wtcf_tour_banner} className="wtcf_tour_banner" alt="" />
            </div>
            <div className="col-md nct_download_column">
              <h2 className="nct_text text-white ms-3">
                NATIONAL CHAMPIONS TROPHY 2023
              </h2>
              <div>
                <p className="download_text ms-3">
                  DOWNLOAD THE APP TO REGISTER
                </p>
                <span>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.wtcf"
                    target="_blank"
                  >
                    <img
                      src={NctDownlaod}
                      className="Nct_Downlaod_Icon"
                      alt=""
                    />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
  
        <div className="container mt-3 button_div">
          <span className={header}>
            {
              pathname === "/Tournament" ? <Link to="#Tournament" className="btn tournament_btn text-white " style={{ border: "2px solid #4CE50E" }} > TOURNAMENT INFO</Link> : <Link to="/Tournament"> <button type="button" className="btn tournament_btn text-white">TOURNAMENT INFO</button></Link>
            }

            {
              pathname === "/Rewards" ? <Link to="#Rewards"  className="btn rewards_btn text-white " style={{ border: "2px solid #4CE50E" }}  >REWARDS & PRIZES</Link> : <Link to="/Rewards"><button type="button" className="btn rewards_btn text-white">REWARDS & PRIZES</button></Link>
            }
            {
              pathname === "/Rules" ? <Link to="#Rules" className="btn rules_btn text-white " style={{ border: "2px solid #4CE50E" }} >RULES & REGULATIONS</Link> : <Link to="/Rules"><button type="button" className="btn rules_btn text-white">RULES & REGULATIONS</button></Link>
            }

          </span>
        </div>

        {/* sidemenu */}
        <div className="toggle2">
          <div className="nav-bar">
            <Link to="#" className="menu-bar">
              <img src={list_icon} className="nct_toggler_icon" alt="" onClick={showMenu} />
            </Link>
          </div>
          <nav className={menu ? "nav-menu active" : "nav-menu"}>
            <ul className="nav-menu-items" onClick={showMenu}>
              <li className="sideMenu_close_btn " style={{ listStyleType: "none", Size: "76px", }}>
                <Link to="#" className="menu-bar">
                  <AiOutlineClose style={{ color: "white" }} />
                </Link>
              </li>
              {Data.map((items, index) => {
                return (
                  <li key={index} className={items.className}>
                    <Link to={items.path}>{items.title}</Link>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}
export default TournamentHeader;

import Carousel from "react-grid-carousel";

import React, { useState, useRef } from "react";
// import { Carousel, Button } from "react-bootstrap";
import world_back from "../../images/gallery.png";
import "../../styles/Gallery.css";
import JsonData from "../../json/news-details.json";
import Slider from "react-slick";

function Gallery() {
  // const [index, setIndex] = useState(0);
  // const ref = useRef(null);
  // const handleSelect = (selectedIndex, e) => {
  //   setIndex(selectedIndex);
  // };

  // const sliderSettings = {
  //   arrows: false,
  //   slidesToShow: 2,
  //   slidesToScroll: 1,
  //   infinite: true,
  // }

  // const onPrevClick = () => {
  //   ref.current.prev();
  // };
  // const onNextClick = () => {
  //   ref.current.next();
  // };

  return (
    <div className="top_image_view">
      <div>
        <div className="container wtcf_gallery_content_head text-white">
          <h1 id="wtcf_world_cup_head">
            WTCF<span className="text-warning"> WORLD CUP</span>
          </h1>
          <p id="wtcf_world_cup_contents" className="col-6">
            WTCF is working on a wide range of topics from improving the Tennis
            Ball Cricket and developing the Game and Ensuring better Tennis
            Cricket Governing Structures
          </p>
        </div>
        <img className="col-12" src={world_back} width="100%" />
      </div>

      <div className="container mt-5 mb-4">
        <h1 className="container ms-2 text-white">LATEST VIDEOS</h1>
        
        {/* <div className="button-container">
          <div className="container heloooooo" >
            <button variant="primary" onClick={onPrevClick}>
              <h1 class="fa fa arrow"> Previous</h1>
            </button>
            <button variant="primary" onClick={onNextClick}>
              Next
            </button>
          </div>
        </div> */}

        <Carousel cols={3} rows={1} gap={20}>
          {JsonData.GALLERY.map((gallery,index) => {
            return (
              <Carousel.Item key={index} className="bg-primary">
                <img width="100%" height="200px" src={gallery.IMG_URL} />
                <button className="mt-2 btn btn-danger">
                  WTCF T10 WORLD CUP
                </button>
                <p className="mt-2 text-white">{gallery.TITLE}</p>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>

      <div className="container mb-4">
        <h1 className="ms-4 text-white">PHOTO ALBUMS</h1>

        <Carousel cols={3} rows={1} gap={20}>
          {JsonData.NEWS.map((albums) => {
            return (
              <Carousel.Item className="bg-primary">
                <img width="100%" height="200px" src={albums.IMG_URL} />
                <button className="mt-2 btn btn-danger">
                  WTCF T10 WORLD CUP
                </button>
                <p className="mt-2 text-white">{albums.TITLE}</p>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
}
export default Gallery;

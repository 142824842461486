
export const faqData = [
    {
        question: "Why should I register with the WTCF?",
        answer: <p>The World Tennis Cricket Federation aspires to be the primary agency in responsible for  organising, developing, promoting, and managing tennis ball cricket tournaments around the globe. By becoming a member of WTCF, you will be able to take part in cricket competitions that are based on other well-known cricket leagues, such as the IPL, PSL, and Big Bash. Those who register will also have the opportunity to take part in WTCF's highly successful ascent to the position of top tennis ball cricket organisation.</p>
    },
    {
        question: "How can I Become an Associate Member of WTCF?",
        answer: <p>
            <br/> 1.    Be passionate about the game of cricket.<br/>
            <br />2.	Playing or Coaching Experience will be an added advantage.<br />
            <br />3.	Have a strong connection to the local cricketing fraternity and community.<br />
            <br />4.	Have a suitable office infrastructure in a business district with a help desk and area for branding and displaying WTCF materials.
        </p>

    },
    {
        question: "What would be expected from an Associate Nation Member of WTCF?",
        answer: <p><br/>
            The objective of the Associate Nation Member is to promote WTCF in their nation by identifying and selecting like-minded people who can represent WTCF as an ASSOCIATE MEMBER in their State, City, Town, or County.<br/>
            <br />1.	As designated for the season, encourage and counsel teams and individual players on the basic goal of WTCF and push registrations onto its platform.<br/>
            <br />2.	Encourage and support WTCF’s good governance values<br/>
            <br />3.	To support and encourage the goals of WTCF<br/>
            <br />4.	Serve as the team members' sole point of contact who has registered with WTCF.<br/>
            <br />5.	Support the Associate members' efforts to organise and successfully run the playing season in accordance with the WTCF's rules.<br/>
            <br />6.	To promote WTCF's enduring legacy at every league game throughout the playing season.<br/>
            <br />7.	WTCF members are expected to act honorably and in a way that enhances the reputation of the Federation and cricket in general.<br/>
            <br />8.	In their interactions with the WTCF Board and other parties, we anticipate the Associate Member members to be Professional, Reliable, Trustworthy, and Honest.<br/>
            <br />9.	We expect the Associate Nation and its Members to abide by all laws, professional codes, and rules that apply to the WTCF and the game of cricket.<br/>
            <br />10.	Members are expected to uphold and support this goal because WTCF is totally committed to giving everyone connected to the Federation a platform and a positive playing environment.<br/>
            <br />11.	There may be no harassment or discrimination of any kind during any Federation activity.<br/>
            <br />12.	Adopting the highest levels of safety for competitors, spectators, and support personnel, in accordance with the WTCF-defined regulations, and conducting all Games in a safe and inclusive environment.<br/>
            <br />13.	Identify and register all umpires, scorers, and commentators in their state or city, and make sure they are added to the WTCF system after our certification procedure.<br/>
            <br />14.	If applicable, supporting the sporting events in your city<br/>
            <br />15.	Selecting playing locations to hold successful league games; locations should adhere to WTCF guidelines.<br/>

        </p>
    },
    {
        question: "How does the WTCF league function?",
        answer: <p>
            <br /> 1.	WTCF domestic league tournaments will operate on the basis of structures that are globally accepted and are prevalent in Countries that play the game of Cricket.<br/>
            <br />2.	WTCF Platform's Module will serve as a registry of records for all the teams and individual players enrolled with the league; the Registry will contain all the information and statistics about each team and player based on their performance.<br/>
            <br />3.	 Based on their cumulative performance, teams and players will be ranked according to the app's algorithm, and the team with the highest rating will represent their region, city, or state in the WTCF Signature Tournaments of the Associate Nation.<br/>
            <br />4.	Member Ship fees to be decided by the Associate Member, in consultation with the WTCF Global Administrator.<br/>
            <br />The Process:<br/>
            <br />1.	Teams desirous of being associated with WTCF will need to down load the app and register their team in the WTCF Team Registry.<br/>
            <br />2.	The maximum numbers of team members that can be enrolled are 22, and all team members must be registered and verified before the start of the qualifying season of the particular Associate Nation.<br/>
            <br />3.	WTCF's qualification season typically runs from January to June, but it will be the responsibility of the Associate Nation Member to earmark the qualifying season for their Country, based on the Local playing conditions.<br/>
            <br />4.	During the qualifying Season a registered team must log a maximum of 18 matches on the WTCF Scoring platform, along with the video recording of the Games.<br/>
            <br />5.	It is crucial for a registered team to keep track of each of its 18 games; this will help to maintain and record the team's performance and statistics, as well as the individual records of each team member. Based on the team's wins and each player's performance throughout the qualifying season, the team will then be ranked accordingly.<br/>
            <br />6.	The Registry will compare the season statistics of all registered Teams and Individual players based on their performance during the season and rank them after the Qualifying Season is over.<br/>
            <br />7.	Only the Top Ranked Teams from across the Country will move to the next level of Team Competitions.<br/>
            <br />8.	Following the conclusion of the Tournaments of an Associate Nation, an 18-member National Team that will represent the country will be chosen from the Teams that have qualified for the National Champions Trophy of the Associate Nation.<br/>
            <br />9.	This 18-person team will represent the Associate Nation in all international competitions, including the T20 Champions Trophy and WTCF T20 World Cup.<br/>
            <br />10.	Teams that have not yet qualified for the signature WTCF tournaments should keep registering games in the WTCF registry (a maximum of 10 games are allowed), as this will improve their INDIVIDUAL standing in the leader board and increase their chances of making it into the top 500 pool of players who will be eligible for the SUPER LEAGUE's auction pool.<br/>

        </p>
    },
    {
        question: "	What is the WTCF SUPER LEAGUE?",
        answer: <p>
            <br />1.	Themed on the Lines of the successful Franchise Based Leagues like the IPL, BIG BASH & CPL.WTCF- TENNIS BALL SUPER LEAGUE (TBSL) is the result of all the effort each player has put forth throughout the whole qualifying season.<br/>
            <br />2.	Through a bid process, WTCF will invite purchasers for 8 teams<br/>
            <br />3.	During the SUPER LEAGUE AUCTION, the TOP 500 players, ranked according to their individual performance records, will be drawn from the PLAYERS POOL.<br/>
            <br />4.	WTCF rating system's algorithm will be used to determine a player's basic value.<br/>
            <br />5.	As a result, it's crucial that every WTCF-registered player submits their score sheets and game logs to the registration so that their individual records are current. This will aid them in their standing in the WTCF LEADER BOARD.<br/>
            <br />6.	Players forming the top 10% of the of an Associate Nation will be eligible to be Nominated for TBSL in other Associated countries.<br/>
            <br />7.	Interested in being associated with the WTCF movement write to us query<a href="mailto:+@wtcf.cricket" className="text-decoration-none text-white">@wtcf.cricket</a>, our representatives will reach out to you with all the required information. You may also visit our website <a href="www.wtcf.cricket" className="text-decoration-none text-white">www.wtcf.cricket</a><br/>

        </p>
    }
]


import React from "react";
import "../styles/Footer.css";
import Fb_logo from "../images/facebook_logo.png";
import twitter_logo from "../images/twitter_logo.png";
import youtube_logo from "../images/youtube_logo.png";
import insta_logo from "../images/instagram_logo.png";
import linkedin from "../images/linkedin_logo.png";
import { useLocation } from "react-router-dom";
import NctFooter from "./tournaments/NationalChampionsTrophy/NctFooter";


function Footer() {
  const location = useLocation();
  const locationPath = location.pathname;
  // console.log(locationPath);

  return locationPath === "/Tournament"  || locationPath ==="/Rewards" || locationPath === "/Rules" ?(
    <NctFooter></NctFooter>
  ) : (
    <div>
      <section
        className="container-fluid bg-black text-white pt-5"
        style={{ position: "absolute", bottom: "auto" }}
      >
        <div className="container">
          <div className="row">
            {/* <div className='col'></div> */}

            <div className="col explore">
              <h6 style={{ color: "#FFCA05", fontFamily: "montserrat" }}>
                EXPLORE
              </h6>
              <ul className="list-unstyled ">
                {/* <li className='mt-2'><a className='text-decoration-none text-white' href='#'>Events</a></li> */}
                <li className="mt-2">
                  <a className="text-decoration-none text-white" href="/faq">
                    <p className="just_text">FAQ</p>
                  </a>
                </li>
                {/* <li className='mt-2'><a className='text-decoration-none text-white' href='/blog'><p className='just_text'>Blog</p></a></li> */}
                <li className="mt-2">
                  <a className="text-decoration-none text-white" href="/news">
                    <p className="just_text">News</p>
                  </a>
                </li>
                <li className="mt-2">
                  <a
                    className="text-decoration-none text-white"
                    href="/success-stories"
                  >
                    <p className="just_text">Success Story</p>
                  </a>
                </li>
              </ul>
            </div>
            {/* <div className='col'></div> */}

            <div className="col  more">
              <h6 style={{ color: "#FFCA05", fontFamily: "montserrat" }}>
                MORE
              </h6>
              <ul className="list-unstyled">
                <li className="mt-2">
                  <a
                    className="text-decoration-none text-white"
                    href="/terms-and-conditions"
                  >
                    <p className="just_text">Terms & Conditions</p>
                  </a>
                </li>
                <li className="mt-2">
                  <a
                    className="text-decoration-none text-white"
                    href="/privacy-policy"
                  >
                    <p className="just_text">Privacy Policy</p>
                  </a>
                </li>
                <li className="mt-2">
                  <a
                    className="text-decoration-none text-white"
                    href="/contact"
                  >
                    <p className="just_text">Contact us</p>
                  </a>
                </li>
              </ul>
            </div>
            {/* <div className='col'></div> */}

            <div className="row col social">
              <div className="col-md-8">
                <h6
                  className=""
                  style={{ color: "#FFCA05", fontFamily: "montserrat" }}
                >
                  SOCIAL MEDIA
                </h6>
                <ul className="list-unstyled d-flex justify-content-start">
                  <li>
                    <a
                      className="text-white social_links_bottom"
                      href="https://www.facebook.com/World-Tennis-Cricket-Federation-109797411735646"
                      target="_blank"
                    >
                      <img className="social_logo_footer" src={Fb_logo} />
                    </a>
                  </li>
                  <li className="ms-4">
                    <a
                      className="text-white social_links_bottom"
                      href="https://twitter.com/WTCFofficial"
                      target="_blank"
                    >
                      <img className="social_logo_footer" src={twitter_logo} />
                    </a>
                  </li>
                  <li className="ms-4">
                    <a
                      className="text-white social_links_bottom"
                      href="https://www.youtube.com/channel/UC1LG-nsVj4qB3DRzMBeOcFA"
                      target="_blank"
                    >
                      <img className="social_logo_footer" src={youtube_logo} />
                    </a>
                  </li>
                  <li className="ms-4">
                    <a
                      className="text-white social_links_bottom"
                      href="https://www.instagram.com/wtcf.official"
                      target="_blank"
                    >
                      <img className="social_logo_footer" src={insta_logo} />
                    </a>
                  </li>
                  <li className="ms-4">
                    <a
                      className="text-white social_links_bottom"
                      href="https://www.linkedin.com/company/wtcf-cricket/"
                      target="_blank"
                    >
                      <img className="social_logo_footer" src={linkedin} />
                    </a>
                  </li>
                </ul>
              </div>

              {/* <div className='fixed-bottom mb-3'>
                                <li className='list-unstyled text-white float-end'><a className="text-black" href="#"><i className="fa-solid fa-arrow-up"></i></a></li>
                            </div> */}
            </div>

            <div>
              <hr className="horizontal" />
              <div className="container copyright mt-1 pb-4">
                <p>Copyright 2022 - 2023 | World Tennis Cricket Federation</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;

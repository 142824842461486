import "../../styles/news_page.css";
import news_bg_1 from "../../images/news.png";
import news_sample_2 from "../../images/news_sample_2.png";
import news_sample_3 from "../../images/news_sample_3.png";
import news_sample_4 from "../../images/news_sample_4.png";
import {useNavigate} from 'react-router-dom'
import SiteData from "../../json/news-details.json";
function News1() {

  const JsonData = SiteData.NEWS;

  const navigate=useNavigate()

  function transfer(index) {
    navigate(`/news-details/${index}`);
    window.location.reload();
  }
  function transfer1(index) {
    navigate(`/news-details/0`);
    window.location.reload();
  }

  // console.log(JsonData)
  return (
    <div className="news_one top_image_view">
      <section>
        <div>
          <img className="img-fluid" src={news_bg_1} width="100%" />
        </div>
      </section>
      <section>
        <div className="container py-5">
          <div className="row">
            <div className="col-md-6" onClick={()=>{transfer1()}}>
              {/* <a href="/news-details1"> */}
                <img className="img-fluid" src={JsonData[0].IMG_URL} />
              {/* </a> */}
              <div className="text-white my-1">
                <h5>{JsonData[0].TITLE}</h5>
                <h6 className="contents">
                 {JsonData[0].CONTENT}<br />
                </h6>
                <p>
                  <i
                    className="fa fa-calendar"
                    style={{ color: "#E10000" }}
                  ></i>
                  23-Aug-2022
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-8 col-sm-2 col-md-6">
                  <h1 id="recent_news">RECENT NEWS</h1>
                </div>
                <div className="col-4 col-sm-10 col-md-6 chevron_left_right_buttons">
                  <span className="text-black arrows float-end">
                    <i
                      className="bg-white fa fa-chevron-left"
                      style={{ fontSize: "1.3rem", padding: "5px 10px" }}
                    ></i>{" "}
                    <i
                      className="bg-white fa fa-chevron-right"
                      style={{ fontSize: "1.3rem", padding: "5px 10px" }}
                    ></i>
                  </span>
                </div>
              </div>


              {JsonData.map((news,index) => (
                  <div key={index} className="row py-2">
                    <div className="col-md-5" onClick={()=>transfer(index)}>
                        {/* <a href={news.URL}> */}
                      <img src={news.IMG_URL} width={160} height={100} />
                      {/* </a> */}
                    </div>
                    <div className="col-md-7">
                      <div className="text-white">
                        <span>
                          <i
                            className="fa fa-calendar"
                            style={{ color: "#E10000" }}
                          ></i>{" "}
                          {news.DATE}
                        </span>
                        <h6 className="content_heading">{news.TITLE}</h6>
                        <h6 className="news_contents">{news.CONTENT}</h6>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default News1;

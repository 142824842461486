import "../../styles/WorldCup.css";
import World_cup_img from "../../images/world_cup_bac.png";
// import { NewsT } from "../NewsTesting/NewsT";
import WorldCupFaq from "./WorldCupFaq";
import "../NewsTesting/NewsT.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SiteData from "../../json/news-details.json";
import { useNavigate } from "react-router-dom";

const data = SiteData.NEWS.slice().reverse();
const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    // {
    //   breakpoint: 480,
    //   settings: {
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //   },
    // },
  ],
};
function World_cup() {
  const navigate = useNavigate();

  function transfer(index) {
    navigate(`/news-details/${index}`);
  }

  return (
    <div className="top_image_view">
      <img className="world_cup_image" src={World_cup_img} alt="" />
      <div className="world_cup_border">
        <h1 className="heading_bg_wc_title world_cup_text"><span>WTCF WORLD CUP</span></h1>
      </div>
      <div className="container" id="world_cup_container">
        <p className="text-light mt-5">
          The Signature tournament in the WTCF playing Calender,WTCF World cup
          will be a competition that will attract the best the Tennis Ball
          Cricket Talent from across the globe.The competition will help create
          the right platform for other countries to adopt this version of
          Cricket.{" "}
        </p>
        <div>
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1000"
          >
            <div
              className="container news_outer_div my-5"
              style={{ position: "relative" }}
            >
              <div className="news_title_div">
                <h1 className="news_title_text heading_bg_wc_news">
                  <span id="news_letter">NEWS</span>
                </h1>
              </div>
              {/* <SectionTitle title="NEWS" />  */}
              <div className="news_child_div container">
                <Slider {...settings}>
                  {data.map((item, index) => {
                    return (
                      <div key={index}
                        className="inner_news_div"
                        style={{ margin: "10px" }}
                        onClick={() => transfer(item.ID)}
                      >
                        <img
                          className="image card-top"
                          src={item.IMG_URL}
                          alt=""
                        />
                        <div className="card-bottom">
                          <p className="newT_topic">{item.TITLE}</p>
                          <h6
                            className="newT_description"
                            style={{ color: "black" }}
                          >
                            {item.CONTENT}
                          </h6>
                          <div className="d-flex justify-content-between">
                            <span className="newsT_date ms-3 mb-1">
                              {item.DATE}
                            </span>
                            <span
                              className="know_more_btn text-danger  me-4 mb-1"
                              style={{ color: "#E10000" }}
                            >
                              Know More
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
        <div>
          <WorldCupFaq />
        </div>
      </div>
    </div>
  );
}
export default World_cup;

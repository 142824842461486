import web_video from "../../images/Website_Video.mp4";
import "../../styles/section1.css";
import React, { useState } from "react";
// import button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import popup_header from "../../images/popup_header_img.png";
import Ratio from 'react-bootstrap/Ratio';

import axios from 'axios';
import qs from 'qs';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function TeaserVideo() {
  const inputHandler = (e) => {
    const { value, maxLength } = e.target;
    if (String(value).length >= maxLength) {
      e.preventDefault();
      return;
    }
  };

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const [show, setShow] = useState(false);

  const handleClose = (e) => {
    setShow(false);
    window.location.reload(false);
  }

  const handleShow = () => setShow(true);


  const handleName = (e) => {
    setName(e.target.value);

  }

  const handleEmail = (e) => {
    setEmail(e.target.value);

  }
  const handlePhone = (e) => {
    setPhone(e.target.value);


  }
  const handleMessage = (e) => {
    setMessage(e.target.value);

  }


  const handleSubmit = (e) => {
    e.preventDefault();
    // if (form.checkValidity() === false) {
    //   e.stopPropagation();
    //   e.preventDefault();
    //   toast.warning("NOT SUBMITTED", { autoClose: 3000, position: toast.POSITION.TOP_LEFT });
    // }
    // else {

    //   // alert("thank you submitting")
    //   toast.success("Submitted", { autoClose: 10000, position: toast.POSITION.TOP_CENTER});
    // }
  
    // setValidated(true);

    const reqData = {
      mod: 'WTCF',
      actionType: "add-wtcf-query",
      subAction: JSON.stringify({
        NAME: name,
        EMAIL: email,
        PHONE_NUMBER: phone,
        CATEGORY: "WTCF QUERY",
        MESSAGE: message,

      })
    };


    axios({
      method: 'post',
      url: 'https://api.cricdost.com',
      data: qs.stringify(reqData),
      header: { "content-type": "application/x-www-form-urlencoded" }
    })

      .then(response => {

        if (response.data.XSCStatus == 0) {
          
          toast.success("Submitted",{autoClose:2500,position: toast.POSITION.TOP_CENTER});
          window.setTimeout(()=>{window.location.reload()},3000)
        }
        else {

        } 

      })
      .catch(error => {
        // console.log(error.data);
      });




  }



  return (
    <section className="top_image_view">

<div>
      <div className="container-fluid">
        <div className="row ">
          <div className="col-6 teaser_content">
            <h1 className="powering_heading text-white">
              POWERING THE WORLD <br />
              OF TENNIS BALL <br />
              CRICKET
            </h1>
            {/* <p className="powering_para">A Platform for our Tennis Ball Cricketing heroes to show case and monetize their talent.</p> */}
            <button
              onClick={handleShow}
              className="text-white make_enq_btn">
             <span> Make an Enquiry</span>
            </button>
            <Modal className="myModal mt-2" centered show={show} onHide={handleClose}>
              <div className="">
                <p className="close_option" onClick={handleClose}>
                  <i className="fa fa-times"></i>
                </p>
                <img
                  src={popup_header}
                  width={500}
                  height={100}
                  className="img-fluid modal_image"
                />
                <div className="modal_heading">CONTACT US FOR MORE INFO</div>
              
            
              <Modal.Body>
                <div align="center">
                  <Form  onSubmit={handleSubmit}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        className="back_bg"
                        type="text"
                        placeholder="Full Name"
                        name="name"
                        value={name}
                        onChange={handleName}
                        required />
                   
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        className="back_bg"
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={email}
                        onChange={handleEmail}
                      required />
                    
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        className="back_bg"
                        type="number"
                        placeholder="Mobile Number"
                        name="phone"
                        value={phone}
                        onChange={handlePhone}
                        maxLength={16}
                       onKeyPress={inputHandler}
                     required />
                      
                    </Form.Group>

                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                        className="back_bg"
                        as="textarea"
                        rows={4}
                        placeholder="Message"
                        name="message"
                        value={message}
                        onChange={handleMessage} 
                     required />
                   
                    </Form.Group>
                    <div className="d-grid">
                  <button 
                    size="lg"
                    className="sumbit_btn"
                    id="submit" value="SUBMIT"
                    >
                    SUBMIT
                  </button>
                </div>
              
                  </Form>
                </div>
               
              </Modal.Body>
              </div>
            </Modal>
          </div>
          <div className="col-6"></div>
        </div>


        </div>
        <div className="">
          <Ratio aspectRatio="16x9">
            <video autoPlay={"autoplay"} muted loop id="myVideo">
              <source src={web_video} width="100%" />
            </video>
          </Ratio>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
}
export default TeaserVideo;

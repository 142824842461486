import React from "react";
import "../../styles/member_countries.css";

// flags
import Australia from "../../images/flags/Australia_flag.png";
import Bahamas from "../../images/flags/Bahamas_flag.png";
import Bangladesh from "../../images/flags/Bangladesh_flag.png";
import Canada from "../../images/flags/Canada_flag.png";
import Cayman from "../../images/flags/Cayman_flag.png";
import Denmark from "../../images/flags/Denmark_flag.png";
import Dubai from "../../images/flags/Dubai_flag.png";
import England from "../../images/flags/England_flag.png";
import HongKong from "../../images/flags/Hongkong_flag.png";
import India from "../../images/flags/India_flag.png";
import Indonesia from "../../images/flags/Indonesia_flag.png";
import Ireland from "../../images/flags/Ireland_flag.png";
import Israel from "../../images/flags/Israel_flag.png";
import Italy from "../../images/flags/Italy_flag.png";
import Kenya from "../../images/flags/Kenya_flag.png";
import Kuwait from "../../images/flags/Kuwait_flag.png";
import Malaysia from "../../images/flags/Malaysia_flag.png";
import Nepal from "../../images/flags/Nepal_flag.png";
import Netherlands from "../../images/flags/Netherlands_flag.png";
import Newzealand from "../../images/flags/Newzealand_flag.png";
import Pakistan from "../../images/flags/Pakistan_flag.png";
import Qatar from "../../images/flags/Qatar_flag.png";
import Saudiarabia from "../../images/flags/Saudiarabia_flag.png";
import Singapore from "../../images/flags/Singapore_flag.png";
import Southafrica from "../../images/flags/Southafrica_flag.png";
import Spain from "../../images/flags/Spain_flag.png";
import Srilanka from "../../images/flags/Srilanka_flag.png";
import Sweden from "../../images/flags/Sweden_flag.png";
import Usa from "../../images/flags/Usa_flag.png";
import Uae from "../../images/flags/Dubai_flag.png";
import Westindies from "../../images/flags/Westindies_flag.png";
import Zimbabwe from "../../images/flags/Zimbabwe_flag.png";


const MemCountries = () => {
    return (
        <div className="text-white container"  data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="1000">
            <div className="my-5">
                <h1 className="member_countries_title heading_bg_mem  px-4 text-dark">MEMBER COUNTRIES</h1>

                <div className="mem_countries_flags p-3">

                    <div className="container">
                        <div align="center" className="row countries_flag">

                            <div className="countries_flags_align">
                                <img src={Australia} width={100} />
                                <p>AUSTRALIA</p>
                            </div>
                            <div className="countries_flags_align">
                                <img src={Bahamas} width={100} />
                                <p>BAHAMAS</p>
                            </div>
                            <div className="countries_flags_align">
                                <img src={Bangladesh} width={100} />
                                <p>BANGLADESH</p>
                            </div>
                            <div className="countries_flags_align">
                                <img src={Canada} width={100} />
                                <p>CANADA</p>
                            </div>
                            <div className="countries_flags_align">
                                <img src={Cayman} width={100} />
                                <p>CAYMAN</p>
                            </div>
                            <div className="countries_flags_align">
                                <img src={Denmark} width={100} />
                                <p>DENMARK</p>
                            </div>
                            <div className="countries_flags_align">
                                <img src={Dubai} width={100} />
                                <p>DUBAI</p>
                            </div>

                            <div className="countries_flags_align">
                                <img src={England} width={100} />
                                <p>ENGLAND</p>
                            </div>
                            <div className="countries_flags_align">
                                <img src={HongKong} width={100} />
                                <p>HONG KONG</p>
                            </div>
                            <div className="countries_flags_align">
                                <img src={India} width={100} />
                                <p>INDIA</p>
                            </div>
                            <div className="countries_flags_align">
                                <img src={Indonesia} width={100} />
                                <p>INDONESIA</p>
                            </div>
                            <div className="countries_flags_align">
                                <img src={Ireland} width={100} />
                                <p>IRELAND</p>
                            </div>
                            <div className="countries_flags_align">
                                <img src={Israel} width={100} />
                                <p>ISRAEL</p>
                            </div>
                            <div className="countries_flags_align">
                                <img src={Italy} width={100} />
                                <p>ITALY</p>
                            </div>

                            <div className="countries_flags_align">
                                <img src={Kenya} width={100} />
                                <p>KENYA</p>
                            </div>
                            <div className="countries_flags_align">
                                <img src={Kuwait} width={100} />
                                <p>KUWAIT</p>
                            </div>
                            <div className="countries_flags_align">
                                <img src={Malaysia} width={100} />
                                <p>MALAYSIA</p>
                            </div>
                            <div className="countries_flags_align">
                                <img src={Nepal} width={100} />
                                <p>NEPAL</p>
                            </div>
                            <div className="countries_flags_align">
                                <img src={Netherlands} width={100} />
                                <p>NETHER LANDS</p>
                            </div>
                            <div className="countries_flags_align">
                                <img src={Newzealand} width={100} />
                                <p>NEW ZEALAND</p>
                            </div>
                            <div className="countries_flags_align">
                                <img src={Pakistan} width={100} />
                                <p>PAKISTAN</p>
                            </div>

                            <div className="countries_flags_align">
                                <img src={Qatar} width={100} />
                                <p>QATAR</p>
                            </div>
                            <div className="countries_flags_align">
                                <img src={Saudiarabia} width={100} />
                                <p>SAUDI ARABIA</p>
                            </div>
                            <div className="countries_flags_align">
                                <img src={Singapore} width={100} />
                                <p>SINGAPORE</p>
                            </div>
                            <div className="countries_flags_align">
                                <img src={Southafrica} width={100} />
                                <p>SOUTH AFRICA</p>
                            </div>
                            <div className="countries_flags_align">
                                <img src={Spain} width={100} />
                                <p>SPAIN</p>
                            </div>
                            <div className="countries_flags_align">
                                <img src={Srilanka} width={100} />
                                <p>SRI LANKA</p>
                            </div>
                            <div className="countries_flags_align">
                                <img src={Sweden} width={100} />
                                <p>SWEDEN</p>
                            </div>

                            <div className="countries_flags_align">
                                <img src={Usa} width={100} />
                                <p>USA</p>
                            </div>
                            <div className="countries_flags_align">
                                <img src={Uae} width={100} />
                                <p>UAE</p>
                            </div>
                            <div className="countries_flags_align">
                                <img src={Westindies} width={100} />
                                <p>WEST INDIES</p>
                            </div>
                            <div className="countries_flags_align">
                                <img src={Zimbabwe} width={100} />
                                <p>ZIMBABWE</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default MemCountries;
// import logo from './logo.svg';
import '../../styles/Info.css';

import React, { useState } from "react";
import history_image2 from '../../images/pic2.png'
import history_image3 from '../../images/pic3.png'

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text.slice(0, 250) : text}
      <br /><span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? <h6>Learn More   <i className="fa fa-arrow-right" style={{ fontSize: "14px" }}></i></h6> : <h6>Show Less   <i className="fa fa-arrow-up" style={{ fontSize: "14px" }}></i></h6>}
      </span>
    </p>
  );
};

function Info() {
  return (

    <div className="history_wtcf px-md-1 mt-2" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="1000">
      <div className="container">
        <section className='px-md-1 py-2'>
          <div className="row px-md-1 py-2">
            <div id="wtcf_description" className="col-md-6 py-3">
              <h1 className="text-white why_wtcf_heading">WHY <span style={{ color: "#E4DC00" }}>WTCF?</span></h1>
              <p className="wtcf_content3 content_color" >WTCF competitions accurately simulate and give the impression of being a capped cricket player. WTCF organises competitions at every level, from the local to the worldwide. This means that having a passion for the game is all that is necessary, regardless of your age or level of experience.
                 </p>
            </div>
            <div id="info_image2" className="col-md-6">
              <div align="" >
                <img src={history_image2} className='info_2_img img-fluid' width="100%" height="100%" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Info;

import NoahSir from '../../images/noah_sir.png'

const Imgdata=[{adv_img:NoahSir,adv_name:"ONEILL NOAH",adv_desc:"Member Since 2022"},
{adv_img:"",adv_name:"",adv_desc:""},
{adv_img:"",adv_name:"",adv_desc:""},
{adv_img:"",adv_name:"",adv_desc:""},
{adv_img:"",adv_name:"",adv_desc:""},
{adv_img:"",adv_name:"",adv_desc:""},
{adv_img:"",adv_name:"",adv_desc:""},
{adv_img:"",adv_name:"",adv_desc:""},
{adv_img:"",adv_name:"",adv_desc:""},
{adv_img:"",adv_name:"",adv_desc:""}]

export default Imgdata;
// import logo from './logo.svg';
import '../../styles/Info.css';

import React, { useState } from "react";
 import info_pic1 from '../../images/info_1.png'
// import history_pic2 from '../../images/history_pic2.png'

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text.slice(0, 250) : text}
      <br /><span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? <h6>Learn More   <i className="fa fa-arrow-right" style={{ fontSize: "14px" }}></i></h6> : <h6>Show Less   <i className="fa fa-arrow-up" style={{ fontSize: "14px" }}></i></h6>}
      </span>
    </p>
  );
};

function Info() {
  return (

      <div className="container mt-5"  data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="1000">
        <section>
          <div className="row">
            <div className="text-white" >
              <h1 id="heading_history">THE HISTORY OF <br /><span style={{ color: "#E4DC00" }}>TENNIS BALL CRICKET</span></h1>
            </div>
            </div>
            <div className="row">
              <div className="col-md-4 content_color wtcf_centent1">
                <p>Tennis Ball cricket first gained popularity in the Indian Subcontinent, where people lacked the access to expensive leather cricket equipment.</p>
        </div>
              <div className='col-md-4 '>
              <img src={info_pic1} className="info_content_images" />
                {/* <div className=' pic1 info_content_images' align="center">
                  <img className='history_pic1' src={history_pic1} width={290} height={250} />
                  <img className='history_pic2' src={history_pic2} width={250} height={250} />
                </div> */}
              </div>
              <div className="col-md-4 content_color mt-5">
                
              <p>  Although the actual start date of tennis ball cricket is unknown, it is generally acknowledged that the sport began to gain  popularity in the late 1980s and early 1990s. Tennis ball cricket became increasingly popular in India after televised cricket matches were introduced there. Every youngster aspired to be like their favourite cricket player.</p>
     
              </div>
            </div>
          
        </section>
      </div>
  );
}

export default Info;

// import logo from './logo.svg';
import '../../styles/Info.css';
import React, { useState } from "react";
import history_image3 from '../../images/pic3.png'

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text.slice(0, 250) : text}
      <br /><span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? <h6>Learn More   <i className="fa fa-arrow-right" style={{ fontSize: "14px" }}></i></h6> : <h6>Show Less   <i className="fa fa-arrow-up" style={{ fontSize: "14px" }}></i></h6>}
      </span>
    </p>
  );
};

function Info() {
  return (

    <div className="history_wtcf px-md-1 mb-2" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="1000">
      <div className="container">

        <section className='px-md-1 py-4'>
          <div className='row'>
            <div className=' px-md-1 col-md-6'>
              <img className='info_3_img' src={history_image3} />
            </div>
            <div className='col-md-6'>
              <h1 className="text-white exp-heading">EXPERIENCE THE FEEL OF AN <span className='experience_heading' style={{ color: "#E4DC00" }}>INTERNATIONAL PLAYER</span></h1>
              <p className="wtcf_content4 content_color" >WTCF enables its participants to live and breathe like the capped cricketers do by holding competitions that are identical to many other popular international events like the Indian Premier League, Big Bash League, etc.</p>
              <p className="wtcf_content4 content_color"> The World Tennis Cricket Federation seeks to revolutionise tennis ball cricket worldwide. WTCF seeks to Structure, Organize, and Promote Tennis Ball Cricket as the sport's top regulating body.
                 </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Info;

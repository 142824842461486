import "../styles/Header.css";
import "../styles/stylesheet.css";
import "../styles/menu_nav.scss";
import wtcf_logo from "../images/wtcf_logo.png";
import title_bg from "../images/new_header_bg.png";
import Fb_logo from "../images/facebook_logo.png";
import twitter_logo from "../images/twitter_logo.png";
import youtube_logo from "../images/youtube_logo.png";
import insta_logo from "../images/instagram_logo.png";
import nav_bg from "../images/header_center_bg.png";
import { useLocation } from "react-router-dom";
import Nct from "./tournaments/NationalChampionsTrophy/Nct";

export function Layout() {
  const location = useLocation();

  const lastSegment = location.pathname;

  return lastSegment === "/Tournament" ||
    lastSegment === "/Rewards" ||
    lastSegment === "/Rules" ? (
    <Nct />
  ) : (
    // return(
    <div>
      <div className="fixed-top  header_bg_color">
        <img
          src={title_bg}
          className=" d-none d-lg-block title_bg_img img-fluid0"
        />

        <div>
          <div className="d-none d-lg-block">
            <nav className=" container top_bar fixed-top">
              <ul className=" social_links_topbar">
                <li className=" nav_list ">
                  <a
                    href="https://www.facebook.com/World-Tennis-Cricket-Federation-109797411735646"
                    target="_blank"
                  >
                    <img className="social_logo" src={Fb_logo} />
                  </a>
                </li>
                <li className="nav_list ">
                  <a href="https://twitter.com/WTCFofficial" target="_blank">
                    <img className="social_logo" src={twitter_logo} />
                  </a>
                </li>
                <li className="nav_list ">
                  <a
                    href="https://www.youtube.com/channel/UC1LG-nsVj4qB3DRzMBeOcFA"
                    target="_blank"
                  >
                    <img className="social_logo" src={youtube_logo} />
                  </a>
                </li>
                <li className="nav_list ">
                  <a
                    href="https://www.instagram.com/wtcf.official"
                    target="_blank"
                  >
                    <img className="social_logo" src={insta_logo} />
                  </a>
                </li>
              </ul>
            </nav>
          </div>

          <a href="/">
            <div className="container wtcf_logo_abbr">
              <div>
                <img src={wtcf_logo} className="wtcf_logo " />
              </div>
              <div className="website_title">WTCF</div>
              <div className="website_tagline">
                WORLD TENNIS CRICKET FEDERATION
              </div>
            </div>
          </a>

          <img src={nav_bg} className=" nav_bar_bg img-fluid" />
          <nav className="nav_wholebar navbar-dark navbar navbar-expand-lg">
            <div className="container">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#navbarOffcanvas"
                aria-controls="navbarOffcanvas"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="fa-solid fa-bars" style={{ color: "white" }}></i>
              </button>
              <div
                className="offcanvas offcanvas-end "
                id="navbarOffcanvas"
                tabIndex="-1"
                aria-labelledby="offcanvasNavbarLabel"
              >
                <div className="offcanvas-header">
                  <button
                    type="button"
                    className="btn-close btn-close-white text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body">
                  <div className=" menu_bar_position justify-content-end flex-grow-1 pe-3">
                    <ul className="navbar-nav  menu_bar_home">
                      <li className={(lastSegment==="/")?"nav-item home-item":"nav-item "}>
                        <a className="nav-link  " aria-current="page" href="/">
                          HOME
                        </a>
                      </li>
                      <li className={(lastSegment==="/success-stories"||lastSegment==="/faq")?"nav-item about-item":"nav-item "}>
                        <a
                          className="nav-link  dropdown-toggle"
                          
                          role="button"
                          // data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          ABOUT US
                        </a>
                        <ul className="dropdown-menu">
                          <li>
                            <a
                              className="dropdown-item "
                              href="/success-stories"
                            >
                              SUCCESS STORIES
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item " href="/faq">
                              FAQ
                            </a>
                          </li>
                          {/* <li>
                            <a
                              className="dropdown-item "
                              href="/board-advisors"
                            >
                              BOARD OF ADVISORS
                            </a>
                          </li> */}
                        </ul>
                      </li>
                      <li className={(lastSegment==="/wtcf-world-cup"||lastSegment==="/superLeague")?"nav-item dropdown tournament-item":"nav-item dropdown "}>
                        <a
                          className="nav-link  dropdown-toggle"
                          href=""
                          role="button"
                          // data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          TOURNAMENTS
                        </a>
                        <ul className="dropdown-menu">
                          <li>
                            <a
                              className="dropdown-item "
                              href="/wtcf-world-cup"
                            >
                              WTCF WORLD CUP
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item " href="/superLeague">
                              WTCF SUPER LEAGUE
                            </a>
                          </li>
                          {/* <li>
                            <a
                              className="dropdown-item "
                              href="/Tournament"
                            >
                              NATIONAL CHAMPIONS TROPHY 
                            </a>
                          </li> */}
                        </ul>
                      </li>
                      {/* <li className="nav-item">
                        <a className="nav-link " href="/gallery">
                          GALLERY
                        </a>
                      </li> */}
                      <li className={(lastSegment==="/contact")?"nav-item contact-item":"nav-item"}>
                        <a className="nav-link " href="/contact">
                          CONTACT US
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </nav>
          {/* <nav className="nav_wholebar ">
              <div className="website_title">WTCF</div>
              <div className="website_tagline">
                WORLD TENNIS CRICKET FEDERATION
              </div>
              <img src={nav_bg} className="nav_bar_bg " />
              <div className="menu_website_bar  ">
                <div
                  align="right"
                  className=" menu_bar_home navbar-collapse offcanvas-collapse"
                  id="navbarsExampleDefault"
                >
                  <ul className="navbar me-4 ">
                    <li className="navbar-item">
                      <a href="/">HOME</a>
                    </li>
                    <li className="navbar-item">
                 
                      <a
                        href="/about"
                        className="dropdown-toggle "
                        data-bs-toggle="dropdown"
                      >
                        ABOUT US
                      </a>
                      <div class="dropdown-menu">
                        <a href="/success-stories" class="dropdown-item">
                          <p>SUCCESS STORIES</p>
                        </a>
                        <a href="/faq" class="dropdown-item">
                          <p>FAQ</p>
                        </a>
                        <a href="/board-advisors" class="dropdown-item">
                          <p>BOARD OF ADVISORS</p>
                        </a>
                      </div>
                    </li>
                    <li className="navbar-item">
                      <a href="/tournament" class="" data-bs-toggle="dropdown">
                        TOURNAMENT
                      </a>
                      <div class="dropdown-menu">
                        <a href="/wtcf-world-cup" class="dropdown-item">
                          <p>WTCF WORLD CUP</p>
                        </a>
                        <a href="superLeague" class="dropdown-item">
                          <p>WTCF SUPER LEAGUE</p>
                        </a>
                      </div>
                    </li>
                    <li className="navbar-item">
                      <a href="/gallery">GALLERY</a>
                    </li>
                    <li className="navbar-item">
                      <a href="/contact">CONTACT</a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav> */}
        </div>
      </div>
    </div>
  );
}

export default Layout;

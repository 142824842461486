import React from "react";
import TournamentHeader from "./Tournament-info";


function Nct(){
    return(
        <>
        <TournamentHeader />
        
        </>
    )
}
export default Nct;
import React from "react";
import { Accordion, AccordionItem } from "react-light-accordion";
import { useState } from 'react';
import "react-light-accordion/demo/css/index.css";
import "../styles/FAQ.css";
import Header from "../components/Header";
import { Footer } from "../components/Footer";
import FAQimg from "../images/FAQ.png";
import FAQimg_gradient from "../images/wtfc_faq_gradient.png";
import new_img from "../images/success_stories_bg_img.png";
import { faqData } from "../components/section/AccordionData";

export const FAQ = () => {
  const [selected, setSelected] = useState(null)
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null)
    }
    setSelected(i)
  }
  return (
    <div className="top_image_view">
      <div className="full_container px-md-1">
        <div>
          <img className="faq_image" src={FAQimg} alt="" />
        </div>

        <div className="container text-white faq_container">
          <div className="my-2 px-md-1 faq_section">
            <h1 className="accordion_heading_home heading_bg_faq text-dark">FAQ</h1>

            <div className='accordion_outer_div'>
              {
                faqData.map((item, i) => {
                  return (
                    <div className="item">
                      <div className="title" onClick={() => toggle(i)}>
                        <h5 className="faq_question">{item.question}</h5>
                        <span className='plus_button'>{selected === i ? '-' : '+'}</span>
                      </div>
                      <div className={selected === i ? 'content show' : 'content'}>
                        <h5 className="ms-4 me-4 faq_answer">{item.answer}</h5>
                      </div>
                    </div>
                  )
                })
              }
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

import React from 'react'
import './BoardAdvTest.css'
import Imgdata from './BoardAdvImg'
export const BoardAdvTest = () => {
   
  return (
    <div>   
      {/* <div className="py-5"></div> */}
        <div className='outer_container py-5 container mb-5'>          
          <div className="advisor_title_div" style={{padding:"1.5px",width:"30vw"}}>
            <h1 className='advisor_title_h' style={{padding:"1vw",wordSpacing:"5px",letterSpacing:"1px",textAlign:"left"}}>BOARD OF ADVISORS</h1>
          </div>
          <div className="board_outer_div container">
            <div className=" container board_inner_div">
                {
                  Imgdata.map(item=>{
                    if(item.adv_img != ""){
                    return(
                      <div className="board_card_div" style={{position:"relative"}}>
                        <img src={item.adv_img} alt="image" />
                        <h1 style={{position:"absolute",bottom:"7%",left:'4%',color:"white",fontSize:"30px"}}>{item.adv_name}</h1>
                        <h5 style={{position:"absolute",bottom:"1%",left:'4%',color:"white",fontSize:"10px",fontSize:"13px",fontWeight:"100"}}>{item.adv_desc}</h5>
                      </div>
                    )
                  }
                  })
                }
            </div>
          </div>
       </div>
    </div>
  )
}

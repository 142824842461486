import "../../styles/section1.css";
import jit_sir from "../../images/Jit_sir.png";
import neil_sir from "../../images/o'neil_sir.png";
function Executives() {
    return (
            <section   data-aos="fade-right" data-aos-duration="1000" >
                <div className="container ">
                    <div className="executive" style={{marginBottom:"-20px"}}>
                    <h1 className="text-white executive_heading"><span>WTCF EXECUTIVE TEAM</span></h1>
                        <div className="row ">
                            <div  className="col-4 col-md-4 text-white" style={{background:""}}>
                                <img className=" jit_img" src={jit_sir}/>
                                <div className="execuive_names ps-2">JIT GOEL</div>
                                <div className="executive_position ps-2"><p>CEO</p></div>
                            </div>
                            <div  className="col-4 col-md-4 text-white mx-3 " style={{background:""}}>
                                <img className=" neil_img" src={neil_sir} />
                                <div className="execuive_names ps-2">ONEILL NOAH</div>
                                <div className="executive_position ps-2"><p>COO</p></div>
                            </div>
                            <div className="col-4 col-md-4">
                            </div>
                            <div className="col-4 col-md-4">
                            </div>
                        </div>
                    </div>
                </div>
            </section>

    );
}
export default Executives;
import Android from "../../images/Android_logo.png";
import Ios from "../../images/Ios_logo.png";
import Downloadapp from "../../images/Wtcf_web.png";
// import Qrcode from "../../images/Qrcode.png";
import "../../styles/section1.css";
const AppDownload=()=>{
    return(
          <div>
            <div className="container download_section_img">
                <a href="https://play.google.com/store/apps/details?id=com.wtcf" target="_blank" ><img src={Android} className="android_logo"/></a>
                <a href="#"><img src={Ios} className="ios_logo" /></a>
            </div>
          <img src={Downloadapp} width="100%"/>
        </div>
    )
}
export default AppDownload;
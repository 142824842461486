import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { faqData } from '../section/AccordionData';
import 'react-light-accordion/demo/css/index.css';
import "../../styles/HomeFaq.css";
// import "../../styles/FAQ.css"
import { useState } from 'react';
const App = () => {
    const [selected, setSelected] = useState(null)
    const arraySlice = faqData.slice(0, 4);
    const toggle = (i) => {
        if (selected === i) {
            return setSelected(null)
        }
        setSelected(i)
    }
    return (

        <div className='container text-white mb-5'>
            <div className=' faq_section v'>
                <h1 className='accordion_heading heading_bg_faq_wc text-dark'><span>FAQ</span></h1>
                <div className='accordion_outer_div'>
                    {
                        arraySlice.map((item, i) => {
                            return (
                                <div key={i} className="item">
                                    <div className="title" onClick={() => toggle(i)}>
                                        <h5 className="faq_question">{item.question}</h5>
                                        <span className='plus_button'>{selected === i ? '-' : '+'}</span>
                                    </div>
                                    <div className={selected === i ? 'content show' : 'content'}>
                                        <h5 className="ms-5 me-5 faq_answer">{item.answer}</h5>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default App;
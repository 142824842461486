import Success_story_bac_cover from "../../images/success_stories.png";
import Success_story_title_img from "../../images/rules_headings.png";
import "../../styles/success_story.css";
import new_img from "../../images/success_stories_bg_img.png";

function Success_story() {
  return (
    <div className="top_image_view">
      <img src={Success_story_bac_cover} width="100%" className="img-fluid" />
      {/* <div
        style={{ position: "absolute", right: "-10%", margin: "-20% 0% 0% 0%" }}
        className="text-white bg_blue_img"
      >
        <img src={new_img} height={1200} />
      </div> */}
      <div className="container my-5">
        <div>
          {/* <img
            src={Success_story_bac_cover}
            width="100%"
            className="img-fluid"
          ></img> */}
          <div className="container my-5">
            <div>
              <h2 id="tb_cricket_title " className="text-black my-2 success_heading  heading_bg_success">
               <span> TENNIS BALL CRICKET PLAYERS</span>
              </h2>
              {/* <img src={Success_story_title_img} width="45%" /> */}
            </div>
            <div id="tb_cricket_title_bac_img" className="text-white">
              
                <p className="fs-4 mt-4">
                  In the Indian Subcontinent, particularly in India and Pakistan, the tennis ball cricket variant is exceedingly well-liked. Some of the players who began playing tennis ball cricket include <span className="text-warning">Sunil Gavaskar, Sachin Tendulkar, Wasim Akram, M.S. Dhoni, Shoaib Akhtar, Jasprit Bumrah, Piyush Chawla, Mohammed Siraj, Natarajan, Varun Chakravarthy and Umran Malik.</span>
                </p>
                <p className="fs-4">
                  India's Most Successful Captian, <span className="text-warning">M.S.Dhoni</span> credits tennis ball cricket for his growth and disposition. The fact that Dhoni's colleagues once purposefully discouraged him from playing tennis ball cricket is even more astonishing. They were concerned that it would undermine his red-ball approach. Dhoni, on the other hand, has never been one to follow the crowd. At Kharagpur, he took part in a number of tennis ball events, and the rest is, as they say, history.
                </p>
                <p className="fs-4">
                  Tennis ball cricket is frequently thought of as an amusement only sport. They are under the impression that it won't benefit them in real life or in their game. The World Tennis Cricket Federation wants you to know the advantages of tennis ball cricket. Continue reading to learn more about the benefits of playing tennis ball cricket.
                </p>
      </div>
          </div>
        </div>
        {/* <div id="tb_cricket_title_bac_img" className="text-white">
          <p className="fs-4">
            <span className="text-warning">
              Wasim Akram, Sachin Tendulkar, Shoaib Akthar, MS Dhoni, Piyush
              Chawla Siraj, Natarajan, Varun CV & Umran Malik.
            </span>{" "}
            Most Cricketers began their career Journey by playing Tennis Ball
            Cricket. Like the Above Names, so many have developed their Game and
            became Successful at what they were doing.
          </p>
          <p className="fs-4">
            Did you know that <span className="text-warning">MS Dhoni</span>, a
            legendary white ball batter and captain, attributes his development
            and temperament to tennis ball cricket? More shocking is the fact
            that Dhoni's teammates once deliberately discouraged him from
            playing tennis ball cricket. They worried it would ruin his red-ball
            strategy. However, Dhoni has never been one to go along with the
            crowd; instead, he participated in several tennis ball competitions
            at Kharagpur, and the rest, as they say, is history.
          </p>
          <p className="fs-4">
            Many individuals believe that tennis ball cricket is only for
            entertainment purposes. They mistakenly think it won't help them in
            their game or in real life. The World Tennis Cricket Federation
            wants to let you know how positively tennis ball cricket may affect
            your life. Discover the advantages of playing tennis ball cricket by
            reading on.
          </p>
        </div> */}
      </div>
    </div>
  );
}
export default Success_story;

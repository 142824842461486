import Success_story_bac_cover from '../../images/success_story_cover.jpg'
import terms_conditions_bac from '../../images/rules_headings.png';
import '../../styles/Terms_condition.css';

function Terms_conditions() {
    return (
        <div className='container-fluid terms_con_posi' >
            <div className="container  py-4">
                <h1 id="terms_heading" className='text-black my-3 heading_bg_tc'>TERMS & CONDITIONS</h1>
                {/* <img id="terms_title_bac" width="35%" src={terms_conditions_bac} /> */}

                <div className='terms_content text-white'>
                    <div id="text_gab_terms">
                        <div id='terms_con_contents mt-4'>
                            <p>Please go through our Terms & Conditions carefully before using any of our Products (Software Tools and Applications) and Services (Paid Subscriptions & Memberships) offered by <a style={{textDecoration: 'none'}} href="https://wtcf.cricket">wtcf.cricket</a></p>
                            <p>Your access to and use of the Service & Products is conditioned on your acceptance of and compliance with these Terms & Conditions. These Terms apply to all Visitors, Users, Customers, and Others who Access or use the Products or Services.</p>
                            <p>By accessing or using the Products & Services, you agree to be bound by these Terms. If you disagree with any or part of the terms then you may not access the Products or Services.</p>
                        </div>

                        <div>
                            <h5>1. USER REGISTRATION</h5>
                            <div>
                                <p>You may use the Site without registration, however, to participate in certain activities of the Site you may be required to create an account. In the event you wish to create an account with us you may do so by completing the registration process. You agree to a) provide true, accurate, correct and complete information as prompted by the applicable registration form b) maintain and update true, accurate, correct and complete information provided by you during the registration process. You may be required to use your mobile number to receive an OTP for making use of our Applications.</p>
                                <p>If at any time Company believes that your account is being misused in any manner, or that the information provided by you during the registration process is not true, inaccurate or incomplete the Company reserves the right to cancel your account and block your access to Site and Applications.</p>
                                <p>You are entirely responsible for any and all activities that occur under your account. You agree to notify the Company of any unauthorized use of your account or any breach of security. The Company will not be liable for any loss that you may incur as a result of someone else using your account or your failure to comply with this section.</p>
                            </div>
                        </div>

                        <div>
                            <h5>2. USE OF THE SITE</h5>
                            <p>All materials provided on this site, including but not limited to all information, materials, text, logos, designs, graphics, images, sounds, software, documents, products and services (collectively, the “Materials”), and the selection, arrangement and display thereof, are the copyrighted works of the Company/or its vendors or suppliers. All Materials herein and all Company software are proprietary to Company and protected by worldwide copyright and other intellectual property laws. Except as stated herein, none of the Materials may be modified, copied, reproduced, distributed, republished, downloaded, displayed, sold, compiled, posted or transmitted in any form or by any means, including but not limited to, electronic, mechanical, photocopying, recording or other means, without the prior express written permission of the Company.</p>
                            <p>Company hereby grants you the right to access and use the Site and Applications only for your personal and non-commercial use. You may not use the Site or Applications for commercial purposes or in any way that is unlawful or harms us or any other person or entity, as determined in our sole discretion.</p>
                            <p>You may post/share links/images/text content on the site on social platforms like Facebook, Twitter, Google+, Pinterest, with appropriate link-back to the original source. Any commercial or business use of the Site or any Material including the Software is expressly prohibited.</p>
                            <p>You acknowledge and agree that nothing in these terms of use shall have the effect of transferring the ownership of any copyrights, trademarks, service marks, trade names or other proprietary rights in the Site or Material or any part thereof to you or any third party.</p>
                        </div>
                        <div>
                            <h5>3. DISCLAIMER</h5>
                            <div>
                                <p>The Site, all the Materials and products (including but not limited to software) and any services, included on or otherwise made available to you through this Site are gathered from a variety of sources and are intended solely as general information and provided on “as is” and “as available” basis without any representation or warranties, express or implied except otherwise specified in writing. They do not constitute advice and should not be relied upon in making (or refraining from making) any decision. Any specific advice or replies to queries in any part of the Site is/are the personal opinion of such experts/consultants/persons and does not constitute or indicate our endorsement, representation or warranty of such information and are not</p>
                                <p>subscribed to by this Site. Without prejudice to the foregoing paragraph, Company does not warrant that:</p>
                                <p>The Site will be constantly available, or available at all; or.<br></br> The information on the Site is complete, legal, true, accurate or non-misleading.</p>
                            </div>
                        </div>

                        <div>
                            <h5>4. LINKS</h5>
                            <div>
                                <p>The Company may establish on the Site a hypertext link to a third party website from time to time. Such link is provided for information and convenience of the users and does not state or imply any sponsorship or endorsement of third party website by Company. The Company has no control over such third party websites and your use of such third party websites or any offsite dealings with such third parties is at your own risk.</p>
                            </div>
                        </div>

                        <div>
                            <h5>5. INTERNATIONAL</h5>
                            <div>
                                <p>USE This Site is controlled and operated from within India. The Company makes no representation that the Site is appropriate or available in locations outside of India. Those who choose to access the Site from other locations do so at their own risk and are responsible for compliance with applicable laws.</p>
                            </div>
                        </div>

                        <div>
                            <h5>6. CHANGES OF TERMS OF USE</h5>
                            <div>
                                <p>We reserve the right, to modify these Terms & Conditions at any time. Any changes to Terms & Conditions will be posted on the Site and your continued use of the Site or Applications following a posting of changes contribute to your agreement to the changes and you are bound by the current version of these Terms & Conditions. If any changes to these Terms & Conditions are unacceptable to you, you must discontinue use of the Site and Applications. We reserve the right to suspend or deny, in our sole discretion, your access to all or any portion of the Site or Applications, including for any violation of any provision of these Terms& Conditions. We also reserve the right to change the Site without notice to you, at any time.</p>
                            </div>
                        </div>
                        <div>
                            <h5>7. REFUND POLICY</h5>
                            <div>
                                <p>The WTCF membership PREMIUM & PRO are non refundable. Your member ship fee will be renewed annually, if the payment method you have chosen for making payments towards WTCF membership supports auto renewal. In case you select an eligible  payment method, the payment of the  membership fees will be on recurring basis( Recurring Payments) and you authorise WTCF to collect the the membership fees for the next membership term(s).</p>
                                <p>In the event Recurring Payments is enabled for your Premium & Pro membership, you agree to be bound by the 'Recurring Payments for WTCF.</p>
                            </div>
                        </div>
                        <div>
                            <h5>8. CONTACT US</h5>
                            <div>
                               <p>If you have any questions about these Terms of Use, please contact us at <a style={{textDecoration: 'none'}} href="mailto:info@wtcf.cricket">info@wtcf.cricket</a> . This policy is EFFECTIVE FROM: May 25, 2022</p> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Terms_conditions;
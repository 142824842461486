import "../../styles/SuperLeague.css";
import Super_league_img from "../../images/Super-league.png";
import super_league from "../../images/super_league.png";
import clapping_img from "../../images/clapping.png";
import bid_image from "../../images/bid.png";
// import { NewsT } from "../NewsTesting/NewsT";
import WorldCupFaq from "./WorldCupFaq";

import "../NewsTesting/NewsT.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SiteData from "../../json/news-details.json";

const data = SiteData.NEWS_SUPERLEAGUE;

export const Super_league = () => {
  return (
    <div className="top_image_view">
              <img
                className="super_league_image"
                src={Super_league_img}
                alt=""
                width="100%"
              />
              <div className="super_border">
                <div classsName="super_league_border">
                  <h1 className="super_league_text heading_bg_sl text-dark">
                    <span>WTCF SUPER LEAGUE</span>
                  </h1>
                </div>
              </div>
              <div className="container super_league_content">
                <div>
                  <p className="text-light mt-5 text-align-justify">
                    The perfect combination between strategy and strength will be
                    discovered in the WTCF TENNIS BALL SUPER LEAGUE (TBSL). Teams will
                    be formed after a competitive bidding process where the talent with
                    highest rating receives the highest value.
                  </p>
                  <p className="text-light mt-2 text-align-justify">
                    This will significantly motivate the players to keep raising their
                    level of play. There will be excellent bowling and hard-hitting
                    batting throughout the competition. This powerhouse tournament will
                    be a treat to watch for all ages.
                  </p>
                </div>
                <div className="mt-5">
                  <img className="super_league" src={super_league} alt="" />
                </div>

                <div className="container">
                  <div className="row mt-5">
                    <div className="col-md-7">
                      <p className="text-white text-align-justify">
                        The tempo of the format will be quick, with just the right
                        amount of entertainment and cricket (Crictainment). The pinnacle
                        of all the effort an individual player has put forth throughout
                        the whole qualifying season will be the WTCF-TBSL (Tennis Ball
                        Super League).
                      </p>
                    </div>

                    <div className="col-md-5">
                      <img
                        className="clapping_image"
                        src={clapping_img}
                        alt=""
                        width="100%"
                      />
                    </div>
                  </div>
                </div>

                <div className="container">
                  <div className="row mt-5 d-flex flex-rap">
                    <div className="col-md-5 order-2 order-lg-1">
                      <img className="bid_image" src={bid_image} alt="" width="100%" />
                    </div>

                    <div className="col-md-7 order-1 order-lg-2">
                      <p className="text-white text-align-justify">
                        Through a competitive bidding process, WTCF will select 8 teams
                        to represent the 8 States of the nation. The PLAYERS POOL,
                        comprised of the top 500 players based on their individual
                        performances at the conclusion of the WTCF playing season, will
                        be put up for auction during the TBSL Auction for the franchise
                        owners.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="mt-5">
                    <div   data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="1000">
                    <div
                        className="container news_outer_div my-5"
                        style={{ position: "relative" }}
                    >
                        <div className="news_title_div">
                        <h1 className="news_title_text heading_bg"><span id="news_letter">News</span></h1>
                        </div>
                        <div className="news_child_div container">
                        <Slider {...settings}>
                            {data.map((item,index) => {
                            return (
                                <div className="inner_news_div" style={{ margin: "10px" }}>
                                <a href={item.URL} style={{textDecoration:"none"}}>
                                <img className="image card-top" src={item.IMG_URL} alt="" />
                                <div className="card-bottom">
                                    <p className="topic">{item.TITLE}</p>
                                    <h6 className="description" style={{color:'black'}}>{item.CONTENT}</h6>
                                    <span className="date">{item.DATE}</span>
                                    <span className="know_more_btn text-danger float-end me-4" style={{color:"#E10000"}}>Know More</span>
                                </div>
                                </a>
                                </div>
                            );
                            })}
                        </Slider>
                        </div>
                    </div>
                    </div>
            </div> */}
              <div className="mt-5">
                <WorldCupFaq />
              </div>
            </div>
            );
};

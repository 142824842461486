/*import {createRoot} from "react-dom/client"*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./HomeNew";
// import AboutUs from "../components/about_page/about_us";
import Gallery from "../components/gallery_page/gallery";

import Contact from "../components/contact/Contact";
// import { NewsT } from "../components/NewsTesting/NewsT";//
import News from "../components/news/news_page";
import Blog from "../components/blog_page/blog";
import { FAQ } from "../pages/FAQ";

import SuccessStories from "../components/success_story.js/success_story";
import TermsAndConditions from "../components/terms_condition/terms_condition";
import PrivacyPolicy from "../components/privacy_policy/privacy_policy";

import WtcfWorldCup from "../components/tournaments/WordCup";
import { Super_league } from "../components/tournaments/SuperLeague";

// import NCT_Header from "../components/tournaments/NationalChampionsTrophy/Header";
// import NCT_Footer from "../components/tournaments/NationalChampionsTrophy/Footer";

import Header from "../components/Header";

// const container = document.getElementById("root");
// const root = createRoot(container);

// import { BoardAdvisors } from "../components/board_advisors/board_advisors";
import { BoardAdvTest } from "../components/board_advisors/BoardAdvTest";
// import NewsDetails from "../components/news/news__details";

import AOS from "aos";
import "aos/dist/aos.css";

import { NewsDetails } from "../components/news/NewsDetails";
import ScrollToTop from "../components/scroll-element/scrollToTop";
import Footer from "../components/Footer";
import TournamentRewards from "../components/tournaments/NationalChampionsTrophy/Rewards";
import Tournament from "../components/tournaments/NationalChampionsTrophy/Tournament";
import Rules from "../components/tournaments/NationalChampionsTrophy/Rules";
export default function App() {
  AOS.init();
  AOS.refresh();
  return (
    <div>
      <BrowserRouter>
        <Header />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/gallery/*" element={<Gallery />} />
          {/* <Route path="/about/*" element={<AboutUs />} /> */}
          <Route path="/superLeague/*" element={<Super_league />} />
          <Route path="/contact/*" element={<Contact />} />
          <Route path="/news/*" element={<News />} />
          <Route path="/news-details/*" element={<NewsDetails />} />
          <Route path="/blog/*" element={<Blog />} />
          <Route path="/faq/*" element={<FAQ />} />
          <Route path="/success-stories/*" element={<SuccessStories />} />
          <Route path="/board-advisors/*" element={<BoardAdvTest />} />
          <Route path="/wtcf-world-cup/*" element={<WtcfWorldCup />} />
          <Route path="/wtcf-super-league/*" element={<Super_league />} />
          {/* <Route path="/Rewards/*" element={<TournamentRewards />} /> */}
          {/* <Route path="/Tournament/*" element={<Tournament />} /> */}
          {/* <Route path="/Rules/*" element={<Rules />} /> */}

          {/* <Route path="/NationalChampionsTrophy/*" element={<NCT_Header />} /> */}
          {/* <Route path="/NationalChampionsTrophyFooter/*" element={<NCT_Footer />} /> */}
          <Route
            path="/terms-and-conditions/*"
            element={<TermsAndConditions />}
          />
          <Route path="/terms-conditions/*" element={<TermsAndConditions />} />

          <Route path="/privacy-policy/*" element={<PrivacyPolicy />} />
          {/* <Route path="/temp/*" element={<Temp />} /> */}
        </Routes>
        <Footer />
      </BrowserRouter>
      <ScrollToTop />
    </div>
  );
}

// ReactDOM.render(<App />, document.getElementById("root"));

// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,

// );

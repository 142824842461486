import '../../styles/privacy_policy.css';

function Privacy_policy() {
    return (
        <div>
            <div className="container my-5" id="priv_policy_posi">
                <div>
                    <h1 id="priv_policy_title" className='my-3 ps-4 text-black heading_bg_pp'>PRIVACY POLICY</h1>
                </div>
                <div className='privacy_content text-white'>
                    <div id="text_gab_policy">
                        <div id="priv_policy_contents">
                            <p>We are committed to protecting and respecting your privacy. This Privacy Policy describes the types of information we may collect from you or that you may provide to us when you visit the World Tennis Cricket Federation website located at <a style={{textDecoration: 'none'}} href="https://wtcf.cricket">https://wtcf.cricket</a> (the “Website”) or the World Tennis Cricket Federation (WTCF) mobile application (“App”) (collectively, the “Services”), and our practices for collecting, using, maintaining, protecting and disclosing such information. This Privacy Policy applies to information we collect: (a) through our Services, (b) in text and electronic messages between you and our Services, and (c) via mobile applications you download from our Websites or Play Store or App Store, which provide dedicated non-browser based interactions between you and our Services.</p>
                            <p>By accessing or using our Services, you agree to this Privacy Policy.</p>
                            <p>IF YOU DO NOT AGREE TO THIS PRIVACY POLICY, PLEASE DO NOT ACCESS OR USE OUR SERVICES.</p>
                            <p> Please read the information below:</p>
                            <p>Information we collect about you<br></br> Where we store data<br></br> How we use your information<br></br> Disclosure of your information <br></br>Updating or correcting your personally identifiable information<br></br> Canceling your account or deleting your personally identifiable information<br></br> Choices regarding control of your personally identifiable information<br></br> Data Security<br></br> Mobile Analytics<br></br> Changes to this Privacy Policy<br></br> Communities, forums, and message boards<br></br> Social Media features<br></br> How do you contact us with questions?</p>
                        </div>

                        <div>
                            <h5>1. INFORMATION WE COLLECT ABOUT YOU</h5>
                            <div>
                                <p>We collect several types of information about the visitors and users of our Services. We may collect this information either directly when you provide it to us or automatically as you navigate through the Websites or App. </p>
                                <p>(a) Personally Identifiable Information. It refers to information by which you may be personally identified, such as your name, email address, or contact number. We will collect and process such data about you when you register for a Service/create an account, subscribe to a newsletter, submit feedback, fill out a survey, or send us a communication. You may also provide information to be published or displayed (“posted”) on public areas of the Websites (such as blogs, message boards, comment sections and the like), or transmitted to other users of the Websites using ‘sharing’ features (collectively, “User Contributions”). Your User Contributions are posted and transmitted to others at your own risk. We cannot control the actions of other users of the Websites with whom you may choose to share your User Contributions. Depending upon the activity, some of the information we ask you to provide is identified as mandatory and some as voluntary. If you do not provide the mandatory data with respect to a particular activity, you will not be able to engage in that activity.</p>
                                <p>We may also collect your Internet Protocol (‘IP’) address. We use your IP address to diagnose problems with our servers, software, to administer our Services and to gather demographic information. We do not collect more Personally Identifiable Information than which is reasonably necessary to participate in an activity on the Websites.</p>
                                <p>(b) Non-Personally Identifiable Information. We may collect certain Non-Personally Identifiable Information that does not by itself identify a specific individual. Such information tells us about your equipment, browsing actions, the resources that you access and use the Services, your operating system, and browser type. The information that we collect automatically is ‘statistical’ in nature. It helps us to deliver a better and more personalized service to users, by enabling us to estimate usage patterns, customize our Services according to your individual preferences, and speed up your searches.</p>
                                <p>(c) Device Information. When you download our App and use our Services, we automatically collect information on the type of device you use and the operating system version, so we know the best application version to provide you with. We require access to your device location services or track any location based information from your mobile device at any time while using our App or Services to offer better service based on that.</p>
                            </div>
                        </div>

                        <div>
                            <h5>2. WHERE WE STORE DATA</h5>
                            <div>
                                <p>The data that we collect from you may be transferred to and stored at, a secure destination. It will be processed only by our staffs who work for us. Such staff may be engaged in, among other things, and the provision of support services. By submitting your personal data, you agree</p>
                                <p>to this transfer, storing or processing. Wtcf.cricket.com will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy.</p>
                            </div>
                        </div>

                        <div>
                            <h5>3. HOW WE USE YOUR INFORMATION</h5>
                            <div>
                                <p> We use information held about you in the following ways:</p>
                                <p>To ensure that content from our Services is presented in the most effective manner for you and for your computer or mobile device<br></br>To provide you with information, products or services that you request from us or which we feel may interest you, where you have consented to be contacted for such purposes<br></br> To allow you to participate in interactive features of our Services when you choose to do so<br></br> To notify you about changes to our Services<br></br> In any other way we may describe when you provide the information<br></br> If you are an existing user, we will only contact you by electronic means (email or SMS) with information about goods and services similar to those which were the subject of a previous interest to you.</p>
                                <p>If you are a new customer, we will contact you by electronic means only if you have consented to this. If you use the Contact Us form on our site, we will respond to your inquiry and delete the information provided after your request has been fulfilled.</p>
                            </div>
                        </div>

                        <div>
                            <h5>4. DISCLOSURE OF YOUR INFORMATION</h5>
                            <div>
                                <p>We will share your Personally Identifiable Information with third parties only in the ways that are described in this Privacy Policy. We do not sell, trade, rent or disclose your information to others, except as provided herein:</p>
                                <p> (b) We will disclose Personally Identifiable Information in the following circumstances: (i) if it is required by law enforcement or judicial authorities, or to cooperate with a law enforcement investigation; (ii) if we have a good faith belief that we are required or permitted to do so by law or legal process; (iii) to protect the rights, reputation, property or safety of Wtcf.cricket (iv) to defend or enforce our rights or your obligations; (v) if the disclosure is required by mandatory professional standards; (vi) to a 3rd party with your prior consent to do so; or (vii) if we are under a duty to disclose or share your Personally Identifiable Information in order to enforce or</p>
                                <p> apply, or any contracts or agreement that may exist between you and Wtcf.cricket including this Privacy Policy and our Website Use Policy. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</p>
                                <p> If you have any inquiries regarding our privacy practices, please contact us directly at <a style={{textDecoration: 'none'}} href="mailto:info@wtcf.cricket">info@wtcf.cricket</a>.</p>
                                <p> You may change any of your Personally Identifiable Information online by logging into ‘My Account’ in accordance with instructions posted elsewhere on our Website.</p>
                                <p> You may also access, request deletion, and correct your personal information and privacy preferences by contacting us through our website Contact us or by emailing us at <a style={{textDecoration: 'none'}} href="mailto:info@wtcf.cricket">info@wtcf.cricket</a></p>
                                <p> Please include your name, address, and e-mail address when you contact us. We encourage you to promptly update your Identifiable Information if it changes. You may ask to have the information on your account deleted or removed and request that we no longer have contact with you; however, because we keep track of past transactions for as long as is reasonably necessary, you cannot delete information associated with past transactions on this Website. In addition, it may be impossible to completely delete your information without some residual information because of backups.</p>
                            </div>
                        </div>

                        <div>
                            <h5>5. CANCELING YOUR ACCOUNT OR DELETING PERSONALLY IDENTIFIABLE INFORMATION</h5>
                            <div>
                                <p>To have any personally identifiable information deleted, you need to send us a request mail to <a style={{textDecoration: 'none'}} href="mailto:info@wtcf.cricket">info@wtcf.cricket</a>.</p>
                                <p>We will retain your information for as long as your account is active or as needed to provide you Services. If you wish to cancel your account or request that we no longer use your information to provide you Services contact us at <a style={{textDecoration: 'none'}} href="mailto:info@wtcf.cricket">info@wtcf.cricket</a> We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements. We will respond to your request to access within 30 days.</p>
                            </div>
                        </div>

                        <div>
                            <h5>6. CHOICES REGARDING CONTROL OF YOUR PERSONALLY IDENTIFIABLE INFORMATION</h5>
                            <div>
                                <p>You can access some of the information that we collect about you. For example, by logging in to your account, you can access information regarding the Websites; Personally Identifiable Information; and communication preferences.</p>
                                <p>With respect to your communications preferences, you can unsubscribe from newsletters by following the instructions included in the newsletter you receive or you can send your requests to <a style={{textDecoration: 'none'}} href="mailto:info@wtcf.cricket">info@wtcf.cricket</a> If your e-mail address has changed and you would like to continue to receive newsletters, you will need to reach us at <a style={{textDecoration: 'none'}} href="mailto:info@wtcf.cricket">info@wtcf.cricket</a> Occasionally we may send out e-mails concerning website disruptions.</p>
                                <p>If you indicated upon registration that you are interested in receiving offers or information from us, we may occasionally send you direct mail about products and services that we feel may be of interest to you. Only Wtcf.cricket. (or agents working on behalf of us and under confidentiality agreements) will send you these direct mailings, and only if you did not later ‘opt-out’ of such offers.</p>
                                <p>We do not send unsolicited commercial e-mails (“spam”). We offer commercial e-mail recipients the opportunity to opt-out of further communications in every e-mail. Please note that we may not include the opt-out information in non-commercial Service e-mails (i.e., e-mails concerning your request, the Website Terms of Service, etc.).</p>
                            </div>
                        </div>

                        <div>
                            <h5>7. DATA SECURITY</h5>
                            <div>
                                <p>All information you provide to us is stored on our secure servers. Access by you to your account is available through a password and/or unique username selected by you. This password is encrypted. We recommend that you do not divulge your password to anyone, that you change your password often using a combination of letters and numbers, and that you ensure you use a secure web browser. We cannot be held accountable for activities that result from your own neglect to safeguard the secrecy of your password and username. If you share a computer with anyone, you should always log out of your account after you are finished, in order to prevent access to your information from subsequent users of that computer.</p>
                                <p>Please notify us as soon as possible if your username or password is compromised. Reach our support through website Contact us form or email us at <a style={{textDecoration: 'none'}} href="mailto:info@wtcf.cricket">info@wtcf.cricket</a></p>
                                <p>Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your Personally Identifiable Information, you acknowledge that: (a) there are security and privacy limitations of the Internet which are beyond our control; (b) the security, integrity and privacy of any and all information and data exchanged between you and us through this Website cannot be guaranteed and we shall have no liability to you or any third party for loss, misuse, disclosure or alteration of such information; and (c) any such information and data may be viewed or tampered with in transit by a third party.</p>
                                <p>In the unlikely event that we believe that the security of your Personally Identifiable Information in our control may have been compromised, we will notify you as promptly as possible under the circumstances. To the extent we have your e-mail address, we may notify you by e-mail and you consent to our use of email as a means of such notification. If you prefer for us to use another method to notify you in this situation, please e-mail us at <a style={{textDecoration: 'none'}} href="mailto:info@wtcf.cricket">info@wtcf.cricket</a> with the alternative contact information you wish to be used.</p>
                            </div>
                        </div>

                        <div>
                            <h5>8. MOBILE ANALYTICS</h5>
                            <div>
                                <p>We use mobile analytics software to allow us to better understand the functionality of our mobile software on your phone. This software may record information such as how often you use the App, the events that occur within the App, aggregated usage, performance data, and where the App was downloaded from. We do not link the information we store within the analytics software to any Personally Identifiable Information you submit within the App.</p>
                            </div>
                        </div>

                        <div>
                            <h5>9. CHANGES TO THIS PRIVACY POLICY</h5>
                            <div>
                                <p> It is our policy to post any changes we make to our Privacy Policy on this page (with a notice that the Privacy Policy has been updated on the home pages of the Websites). If we make material changes to how we treat our users’ information, we will notify you by e-mail to the primary e-mail address specified in your account and through a notice on the home pages of the Websites. The date the Privacy Policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and a deliverable email address for you, and for periodically visiting our Websites and this Privacy Policy to check for any changes</p>
                                <p>Your continued use of the Services after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by the modified Privacy Policy. </p>
                                <p>If we make material changes to how we use Personal Information collected from you, we will notify by email in order to obtain verifiable consent for the Personally Identifiable Information.</p>
                            </div>
                        </div>

                        <div>
                            <h5>10. COMMUNITIES, FORUMS, AND MESSAGE BOARDS</h5>
                            <div>
                                <p>If you choose to disclose Personally Identifiable Information on communities, message boards, forums, and the like, which may be accessible via the Websites, you should be aware that such information, along with any information disclosed in your communication, can be collected and used by third parties and may result in unsolicited messages from third parties. To request removal of your personal information from our blog or community forum, contact us at <a style={{textDecoration: 'none'}} href="mailto:info@wtcf.cricket">info@wtcf.cricket</a>. You acknowledge and accept that any submissions to public areas on the Websites are accessible to all third parties. If you do not want yours or your comments to be viewed by third parties, you are advised not to make any submissions. Ultimately, you are solely responsible for all activity conducted by you via communities, forums, message boards, etc. Please be careful and responsible whenever you are online.</p>
                            </div>
                        </div>

                        <div>
                            <h5>11. SOCIAL MEDIA FEATURES</h5>
                            <div>
                                <p>Our Websites include Social Media Features, such as the Add This button or an interactive mini-program that run on our Websites. These features may collect your IP address, which pages you are visiting on our Websites and may set cookies to enable the features to function properly. Social Media Features are either hosted by a third party or hosted directly on our Websites. Your interactions with these features are governed by the privacy policy of the company providing it.</p>
                            </div>
                        </div>

                        <div>
                            <h5>12. HOW DO YOU CONTACT US WITH QUESTIONS?</h5>
                            <div>
                                <p>If you have any questions about this Privacy Policy, the practices of or your dealings with our Websites, please contact us by email at <a style={{textDecoration: 'none'}} href="mailto:info@wtcf.cricket">info@wtcf.cricket</a> . These policies are EFFECTIVE From: 25th of May 2022</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Privacy_policy;
import React from "react";
import { useState } from "react";
import {useNavigate} from 'react-router-dom'
import "./NewsT.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SiteData from "../../json/news-details.json";


export const NewsT = () => {

  const data = SiteData.NEWS;

  const navigate=useNavigate()

  function transfer(index) {
    navigate(`/news-details/${index}`);
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      // {
      //   breakpoint: 480,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //   },
      // },
    ],
  };

  return (
    <div   data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="1000">
      <div
        className="container news_outer_div my-5"
        style={{ position: "relative" }}
      >
        <div className="news_title_div">
          <h1 className="news_title_text heading_bg_news"><span id="news_letter">NEWS</span></h1>
        </div>
        {/* <SectionTitle title="NEWS" />  */}
        <div className="news_child_div container">
          <Slider {...settings}>
            {data.map((item,index) => {
              return (
                <div key={index} className="inner_news_div" style={{ margin: "10px" }} onClick={()=>transfer(index)}>
                  <img className="image card-top" src={item.IMG_URL} alt="" />
                  <div className="card-bottom m-2">
                    <p className="newT_topic">{item.TITLE}</p>
                    <h6 className="newT_description" style={{color:'black'}}>{item.CONTENT}</h6>
                    <div className="d-flex justify-content-between">
                      <span className="newsT_date ms-3 mb-1">{item.DATE}</span>
                    <span className="know_more_btn text-danger  me-4 mb-1" style={{color:"#E10000"}}>Know More</span>
                    </div>
                    
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

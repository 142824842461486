import "../../styles/blog.css";
import blog_pic1 from "../../images/blog_pic1.png";
import blog_gradient from "../../images/wtfc_faq_gradient.png";
import Card from "react-bootstrap/Card";
import SiteData from "../../json/news-details.json";



function Blog() {
  const JsonData = SiteData.NEWS;

  return (
    <div className="wtcf_blog top_image_view">
      <section>
        <div>
          <img className="img-fluid" src={blog_pic1} width="100%" />
        </div>
      </section>
      <div>
        <img
          className="img-fluid blog_gradient top_image_view"
          src={blog_gradient}
          width="100%"
          height={560}
        />
      </div>

      <section className="py-5">
        <div className="blog_cards container">
          <div className="row">
            <div className="col col-md-4">
              <Card
                className="card_component"
                style={{ width: "90%", height: "96%" }}
              >
                <img
                  className="blog_pics"
                  src={blog_pic1}
                  style={{ height: "175px" }}
                />
                <Card.Body>
                  <Card.Title>
                    <span className="blog_centent_head">
                    India vs Australia| 2022 Highlights
                    </span>
                  </Card.Title>
                  <Card.Text>
                    <small>
                      {" "}
                      WTCF is working on a wide range of topics from improving the Tennis
                    </small>
                  </Card.Text>
                  <span>
                    <i
                      className="fa fa-calendar"
                      style={{ color: "#E10000", fontSize: "15px" }}
                    ></i>{" "}
                    02/02/2022{" "}
                    <span className="sports_details">
                      <i
                        className="fa fa-bars"
                        style={{ color: "#E10000", fontSize: "15px" }}
                      ></i>
                    </span>{" "}
                    Sports
                  </span>
                </Card.Body>
              </Card>
            </div>

            <div className="col col-md-4">
              <Card
                className="card_component"
                style={{ width: "90%", height: "96%" }}
              >
                <img
                  className="blog_pics"
                  src={blog_pic1}
                  style={{ height: "175px" }}
                />
                <Card.Body>
                  <Card.Title>
                    <span className="blog_centent_head">
                      India vs Australia| 2022 Highlights
                    </span>
                  </Card.Title>
                  <Card.Text>
                    <small>
                      {" "}
                      WTCF is working on a wide range of topics from improving the Tennis
                    </small>
                  </Card.Text>
                  <span>
                    <i
                      className="fa fa-calendar"
                      style={{ color: "#E10000", fontSize: "15px" }}
                    ></i>{" "}
                    02/02/2022{" "}
                    <span className="sports_details">
                      <i
                        className="fa fa-bars"
                        style={{ color: "#E10000", fontSize: "15px" }}
                      ></i>
                    </span>{" "}
                    Sports
                  </span>
                </Card.Body>
              </Card>
            </div>

            <div className="col  col-md-4">
              <Card
                className="card_component"
                style={{ width: "90%", height: "96%" }}
              >
                <img
                  className="blog_pics"
                  src={blog_pic1}
                  style={{ height: "175px" }}
                />
                <Card.Body>
                  <Card.Title>
                    <span className="blog_centent_head">
                      India vs Australia| 2022 Highlights
                    </span>
                  </Card.Title>
                  <Card.Text>
                    <small>
                      {" "}
                      WTCF is working on a wide range of topics from improving
                      the Tennis, WTCF is working on a wide range of topics from
                      improving the Tennis
                    </small>
                  </Card.Text>
                  <span>
                    <i
                      className="fa fa-calendar"
                      style={{ color: "#E10000", fontSize: "15px" }}
                    ></i>{" "}
                    02/02/2022{" "}
                    <span className="sports_details">
                      <i
                        className="fa fa-bars"
                        style={{ color: "#E10000", fontSize: "15px" }}
                      ></i>
                    </span>{" "}
                    Sports
                  </span>
                </Card.Body>
              </Card>
            </div>
          </div>

          <div className="row second_row py-5">
            <div className="col col-md-4">
              <Card
                className="card_component"
                style={{ width: "90%", height: "96%" }}
              >
                <img
                  className="blog_pics"
                  src={blog_pic1}
                  style={{ height: "175px" }}
                />
                <Card.Body>
                  <Card.Title>
                    <span className="blog_centent_head">
                      India vs Australia| 2022 Highlights
                    </span>
                  </Card.Title>
                  <Card.Text>
                    <small>
                      {" "}
                      WTCF is working on a wide range of topics from improving
                      the Tennis, WTCF is working on a wide range of topics from
                      improving the Tennis
                    </small>
                  </Card.Text>
                  <span>
                    <i
                      className="fa fa-calendar"
                      style={{ color: "#E10000", fontSize: "15px" }}
                    ></i>{" "}
                    02/02/2022{" "}
                    <span className="sports_details">
                      <i
                        className="fa fa-bars"
                        style={{ color: "#E10000", fontSize: "15px" }}
                      ></i>
                    </span>{" "}
                    Sports
                  </span>
                </Card.Body>
              </Card>
            </div>

            <div className="col col-md-4">
              <Card
                className="card_component"
                style={{ width: "90%", height: "96%" }}
              >
                <img
                  className="blog_pics"
                  src={blog_pic1}
                  style={{ height: "175px" }}
                />
                <Card.Body>
                  <Card.Title>
                    <span className="blog_centent_head">
                      India vs Australia| 2022 Highlights
                    </span>
                  </Card.Title>
                  <Card.Text>
                    <small>
                      {" "}
                      WTCF is working on a wide range of topics from improving
                      the Tennis, WTCF is working on a wide range of topics from
                      improving the Tennis
                    </small>
                  </Card.Text>
                  <span>
                    <i
                      className="fa fa-calendar"
                      style={{ color: "#E10000", fontSize: "15px" }}
                    ></i>{" "}
                    02/02/2022{" "}
                    <span className="sports_details">
                      <i
                        className="fa fa-bars"
                        style={{ color: "#E10000", fontSize: "15px" }}
                      ></i>
                    </span>{" "}
                    Sports
                  </span>
                </Card.Body>
              </Card>
            </div>

            <div className="col col-md-4">
              <Card
                className="card_component"
                style={{ width: "90%", height: "96%" }}
              >
                <img
                  className="blog_pics"
                  src={blog_pic1}
                  style={{ height: "175px" }}
                />
                <Card.Body>
                  <Card.Title>
                    <span className="blog_centent_head">
                      India vs Australia| 2022 Highlights
                    </span>
                  </Card.Title>
                  <Card.Text>
                    <small>
                      {" "}
                      WTCF is working on a wide range of topics from improving
                      the Tennis, WTCF is working on a wide range of topics from
                      improving the Tennis
                    </small>
                  </Card.Text>
                  <span>
                    <i
                      className="fa fa-calendar"
                      style={{ color: "#E10000", fontSize: "15px" }}
                    ></i>{" "}
                    02/02/2022{" "}
                    <span className="sports_details">
                      <i
                        className="fa fa-bars"
                        style={{ color: "#E10000", fontSize: "15px" }}
                      ></i>
                    </span>{" "}
                    Sports
                  </span>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Blog;

import "../styles/section1.css";
import Executives from "../components/section/Executives";
import Missions from "../components/section/Missions";
import TeaserVideo from "../components/section/Teaser";

import Info1 from "../components/section/Info1";
import Info2 from "../components/section/Info2";
import Info3 from "../components/section/Info3";
import { NewsT } from "../components/NewsTesting/NewsT";
import Events from "../components/section/Events";
import Mobile_event from "../components/section/mobile_event";
import HomeFaq from "../components/section/HomeFaq"
import MemberCountries from "../components/section/Member_countries";
import AppDownload from "../components/section/AppDownload";
function Home() {
  return (
    <div>
      <TeaserVideo />
      <NewsT />
      <Info1 />
      <Info2 />
      <Info3 />
      <Events />
      <Mobile_event/>
      <Missions />
      {/* <Executives /> */}
      <HomeFaq />
      <MemberCountries />
      <AppDownload/>
    </div>
  );
}
export default Home;

import "../../styles/section1.css";
import bulleye from "../../images/bullseye-with-dart.png";
import idea_bulb from "../../images/Idea_bulb.png";
function Missions() {
  return (
    <section
      className=""
      data-aos="fade-up"
      data-aos-anchor-placement="top-bottom"
      data-aos-duration="1000"
    >
      {/* <div className="container ">
                  <h1 className="text-white">NEWS</h1>
           </div> */}
      <div className="container px-md-1 py-3 my-4">
        <div className="row">
          <div className="col">
            <h1 className="text-white mission_heading">OUR MISSION & VISION</h1>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-4 col-md-2">
            <img className="img-fluid " src={bulleye} />
          </div>
          <div className="col-8 col-md-10 col-lg-5 text-white">
            <p className="mission_content float-start">
              The World Tennis Cricket Federation seeks to revolutionise tennis
              ball cricket worldwide. WTCF seeks to Structure, Organize, and
              Promote Tennis Ball Cricket as the sport's top regulating body.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-4 col-md-7">
            <img
              className="img-fluid float-end idea_img"
              width="20%"
              src={idea_bulb}
            />
          </div>
          <div className="col-8 col-md-5 text-white my-lg-4 my-2">
            <p className="mission_content idea_content">
              Our vision is to create a platform for cricket fans who are unable
              to play in the major leagues but want to emulate their favourite
              cricketing heroes.
            </p>
          </div>
          <div></div>
        </div>
      </div>
    </section>
  );
}
export default Missions;
